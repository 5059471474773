import { JoinCommunityButton } from "../JoinCommunityButton";
import React from "react";
import { useLocalCommunity } from "./CommunityCard";
import { CardFooter, CircularProgress, useColorModeValue } from "@chakra-ui/react";

export const Footer = () => {
  const community = useLocalCommunity();

  if (!community) return null;
  return (
      <CardFooter className={"community-card-footer"} justifyContent={'end'}>
        {community ? (
            <JoinCommunityButton community={community} />
        ) : (
            <CircularProgress isIndeterminate color="success.300" />
        )}
      </CardFooter>
  );
};
