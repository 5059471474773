import { mode } from "@chakra-ui/theme-tools";

const Textarea = {
  baseStyle: (props) => ({
    borderColor: mode("primary.500", "primary.500")(props),
    _focus: {
      borderColor: "primary.500",
      boxShadow: "0 0 0 2px rgba(116, 105, 246, 0.6)",
    },
    _hover: {
      borderColor: "primary.600",
    },
  }),
};

export default Textarea;
