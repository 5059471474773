import { Container, Fade, Progress, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const REDIRECT_DELAY = 5500;
const PROGRESS_INTERVAL = 50;
const PROGRESS_START = 100;

export const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(PROGRESS_START);
  const { t } = useTranslation();

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((oldProgress) => Math.min(oldProgress - 1, PROGRESS_START));
    }, PROGRESS_INTERVAL);

    const navigateTimeout = setTimeout(() => {
      navigate("/");
    }, REDIRECT_DELAY);

    return () => {
      clearInterval(progressInterval);
      clearTimeout(navigateTimeout);
    };
  }, [navigate]);

  const unRoundedSeconds = progress / 20;
  const remainingSeconds = Math.max(Math.round(unRoundedSeconds), 0);

  return (
    <Container
      height={"full"}
      maxW={"100%"}
      px={"18%"}
      mb={"3%"}
      mt={"15%"}
      gap={5}
      display={"flex"}
      flexDirection={"column"}
    >
      <Text color="primary.400" fontSize={"4xl"} fontWeight={600}>
        {t("pageNotFound")}
      </Text>
      <Progress value={progress} />
      <Fade in={unRoundedSeconds >= 1} unmountOnExit>
        <Text style={{ fontWeight: 700 }}>
          {t('redirectingIn', {remainingSeconds, count: remainingSeconds})}
        </Text>
      </Fade>
      <Fade in={unRoundedSeconds < 0.5} unmountOnExit>
        <Text style={{ fontWeight: 700 }}> {t("redirecting")}</Text>
      </Fade>
    </Container>
  );
};
