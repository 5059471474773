import {
  Flex,
  Text,
  Input,
  Box,
  IconButton,
  Link,
  Divider,
  Progress,
  Button,
  Skeleton,
  SkeletonText,
} from "@chakra-ui/react";
import { DownVoteIcon, UpVoteIcon } from "../constant/icons";
import { formatDistanceToNow } from "date-fns";

const SkeletonCommunityCard = () => (
    <Skeleton height="50px" width="100%" borderRadius="md" />
);

const SkeletonPost = () => (
    <Flex flexDirection="column" alignItems="flex-start" gap="4">
      <SkeletonText noOfLines={1} width="50%" />
      <Skeleton width="50%" height="10px" />
      <Skeleton width="100%" height="40px" />
    </Flex>
);

export function CommunitySkeleton() {
  const numberOfPosts = 5; // Adjust this to the number of skeleton posts you'd like to display

  return (
      <Flex
          alignContent={{ base: "flex-start" }}
          justifyContent={{ base: "center", md: "flex-start" }}
          alignItems={{ base: "flex-start" }}
          flexDirection={{ base: "column", md: "row" }}
          gap={{ base: "6em" }}
      >
        <Flex flexDirection={{ base: "column" }} gap={{ base: "6em" }}>
          <SkeletonCommunityCard />

          <div>
            <SkeletonText noOfLines={1} width="50%" />
            <Skeleton height="40px" width="100%" mt="4" />
            <Skeleton height="300px" width="100%" mt="4" />
            <Flex justify={"flex-end"} mt="3" gap={4}>
              <Skeleton height="32px" width="80px" />
              <Skeleton height="32px" width="80px" />
            </Flex>
          </div>
        </Flex>
        <Flex
            flexDirection={"column"}
            mb={5}
            minWidth={400}
            maxWidth={800}
            gap={4}
        >
          <SkeletonText noOfLines={1} width="50%" />
          {Array(numberOfPosts)
              .fill(null)
              .map((_, i) => (
                  <SkeletonPost key={i} />
              ))}
        </Flex>
      </Flex>
  );
}
