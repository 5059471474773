import {mode} from "@chakra-ui/theme-tools";

const Popover = {
    baseStyle: (props) => ({
        content: {
            bg: mode("white", "gray.700")(props),
            color: mode("gray.800", "white")(props),
            borderRadius: "md",
            boxShadow: mode("lg", "dark-lg")(props),
        },
        arrow: {
            borderColor: mode("gray.200", "gray.500")(props),
        },
    }),
};



export default Popover;
