import {mode} from "@chakra-ui/theme-tools";

const CloseButton = {
    baseStyle: (props) => ({
        _hover: {
            bg: mode("gray.100", "gray.600")(props),
        },
    }),
};


export default CloseButton;
