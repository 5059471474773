import { Icon, IconProps } from "@chakra-ui/react";

export const UpVoteIcon = (props: IconProps) => (
  <Icon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Capa_1"
    {...props}
  >
    <path
      d="M12 4 3 15h6v5h6v-5h6z"
      strokeWidth="1.5"
      stroke={props?.stroke?.toString() ?? "#666"}
      fill={props?.fill?.toString() ?? "none"}
      strokeLinejoin="round"
    ></path>
  </Icon>
);

export const DownVoteIcon = (props: IconProps) => (
  <Icon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m12 20 9-11h-6V4H9v5H3z"
      stroke={props?.stroke?.toString() ?? "#666"}
      fill={props?.fill?.toString() ?? "none"}
      strokeWidth="1.5"
      strokeLinejoin="round"
    ></path>
  </Icon>
);
