import { useContractWrite } from "wagmi"
import { ForumContractAddress } from "../constant/const";
import ForumABI from "../constant/abi/Forum.json";
import { useAppDispatch } from "../state/store";
import { removeCommunity } from "../state/slice";
import { useToast } from "@chakra-ui/react";
import { useLoaderContext } from "../context/LoaderContext";
import { setCacheAtSpecificPath } from "../lib/redis";

export const useRemoveGroup = (groupId: number) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { isLoading, setIsLoading } = useLoaderContext();

  return useContractWrite({
    address: ForumContractAddress as `0x${string}`,
    abi: ForumABI.abi,
    functionName: "removeGroup",
    mode: 'recklesslyUnprepared',
    args: [],
    onError(error, variables, context) {
      toast({
        title: error.name,
        description: error.message,
        status: 'error'
      });
      setIsLoading(false);
    },
    onSuccess: async (data, variables) => {
      setIsLoading(true);
      await data.wait();
      await setCacheAtSpecificPath(`group_${groupId}`, true, '$.data.removed')
      dispatch(removeCommunity({ groupId }))
      toast({
        title: 'Removed Successfully!',
        status: 'success'
      });
      setIsLoading(false);
    },
  })
}