import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  Input,
  Progress,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { BigNumber, ethers } from "ethers";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { useAccount } from "wagmi";
import { useAppSelector } from "../state/store";
import { selectCommunityById, selectIfUserHasjoined } from "../state/slice";
import {
  createNote,
  getContent,
  getIpfsHashFromBytes32,
  hashBytes,
  parsePost,
  postIsConfirmed,
  removeDuplicates,
  sortArray,
} from "../lib/utils";
import { User } from "../lib/model";
import { Post } from "../lib/post";
import Editor from "../components/Editor";
import { OutputData } from "@editorjs/editorjs";
import useSWR, { useSWRConfig } from "swr";
import { useLoaderContext } from "../context/LoaderContext";
import { CommunityCard } from "../components/CommunityCard/CommunityCard";
import { CommunitySkeleton } from "./CommunitySkeleton";
import { NoPosts } from "../components/NoPosts";
import SortBy, { SortByOption } from "../components/SortBy";

import { useValidateUserBalance } from "../utils/useValidateUserBalance";
import { motion } from "framer-motion";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { itemVariants, listVariants } from "../lib/animations/ListAnimations";
import {
  CancelButton,
  PrimaryButton,
  VoteDownButton,
  VoteUpButton,
} from "../components/buttons";
import { useTranslation } from "react-i18next";
import { vote } from "../lib/api";
import { useSortedVotes } from "../hooks/useSortedVotes";
import { HeadingProps } from "@chakra-ui/layout";
import { MotionFlexBanner } from "../components/MotionFlexBanner";
import { AiOutlineFileAdd } from "react-icons/all";
import { AddIcon } from "@chakra-ui/icons";
import { JoinCommunityButton } from "../components/JoinCommunityButton";
import EditGroupModal from "../components/EditGroupModal";

const MotionHeading = motion(Heading);

interface CommunityProps {
  forumContract: ethers.Contract;
  users: any[];
  provider: ethers.providers.BaseProvider;
}

let postInstance: Post = null;

export default function Community({
  forumContract,
  users,
  provider,
}: CommunityProps) {
  const toast = useToast({
    duration: 7000,
    isClosable: true,
    variant: "solid",
  });

  const { id } = useParams();
  const { address } = useAccount();

  const community =
    useAppSelector((state) => selectCommunityById(state, +id)) || null;
  const hasUserJoined =
    useAppSelector((state) => selectIfUserHasjoined(state, address, +id)) ||
    null;

  const { checkUserBalance } = useValidateUserBalance(
    community,
    address,
    provider
  );
  const { setIsLoading, isLoading: isContextLoading } = useLoaderContext();
  const [postTitle, setPostTitle] = useState("");
  const [postDescription, setPostDescription] = useState<OutputData>(null);
  const [sortBy, setSortBy] = useState<SortByOption>("highest");
  const [groupCacheId, setGroupCacheId] = useState<string | null>(null);

  const { mutate } = useSWRConfig();

  const [tempPosts, setTempPosts] = useState([]);
  const postEditorRef = useRef<any>();

  useEffect(() => {
    (async () => {
      postInstance = new Post(null, id, forumContract, provider);
      setGroupCacheId(postInstance.groupCacheId());
      // preload(postInstance.groupCacheId(), fetchPosts);//start fetching before render
    })();
  }, [forumContract]);

  const { data, isLoading } = useSWR(groupCacheId, fetchPosts, {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    let listener1, listener2;
    if (hasUserJoined) {
      provider.once("block", () => {
        listener1 = forumContract.on(
          "NewItem",
          async (
            itemType,
            groupId: BigNumber,
            postId: BigNumber,
            parentId: BigNumber,
            contentCID: string,
            note: BigNumber
          ) => {
            const signal = contentCID;
            const identityCommitment = BigInt(
              hasUserJoined.identityCommitment.toString()
            );
            const generatedNote = await createNote(
              hashBytes(signal),
              identityCommitment
            );

            if (
              itemType === 0 &&
              groupId.toString() === id &&
              note.toString() !== generatedNote.toString()
            ) {
              const p = await forumContract.itemAt(postId?.toNumber());
              let content;
              let block;
              const postIPFShash = getIpfsHashFromBytes32(p?.contentCID);

              await Promise.all([
                getContent(postIPFShash),
                provider.getBlock(p.createdAtBlock.toNumber()),
              ]).then((data) => {
                content = data[0];
                block = data[1];
              });

              const parsedPost = parsePost(content);
              mutate(
                groupCacheId,
                async (posts) => {
                  const confirmedPosts = [...posts]; //remove the previous temp post
                  confirmedPosts.unshift({
                    // and replace it with the one wth the proper id
                    ...parsedPost,
                    createdAt: new Date(block.timestamp * 1000),
                    id: p.id.toString(),
                    upvote: p.upvote?.toNumber(),
                    downvote: p.downvote?.toNumber(),
                  });

                  //this event sometimes fires multple times
                  return sortArray(
                    removeDuplicates(confirmedPosts, "id"),
                    "createdAt",
                    true
                  );
                },
                { revalidate: false }
              );
            }
          }
        );
      });

      provider.once("block", () => {
        listener2 = forumContract.on(
          "VoteItem",
          async (
            voteType: 0 | 1,
            itemType: 0 | 1,
            itemId: BigNumber,
            upvote: BigNumber,
            downvote: BigNumber
          ) => {
            if (itemType === 0) {
              postInstance.updatePostsVote(
                postInstance,
                itemId,
                voteType,
                true
              );
            }
          }
        );
      });
    }
    return () => {
      forumContract.removeAllListeners();
      provider.removeAllListeners();
    };
  }, [hasUserJoined]);

  async function fetchPosts(groupId: string) {
    groupId = groupId.split("_")[0]; //they key comes with an extra string if called by SWR
    let postList = [];

    return await postInstance.getAll();
  }

  const addPost = async () => {
    if (!address) {
      toast({
        title: `Please connect your wallet`,
        status: "error",
      });
      return;
    }
    if (!postTitle || !postDescription) {
      toast({
        title: `Please fill all the fields`,
        status: "error",
      });
      return;
    }
    let ipfsHash;

    if (!checkIfUserHasJoined()) return;
    const hasSufficientBalance = await checkUserBalance();
    if (!hasSufficientBalance) return;

    setIsLoading(true);

    try {
      const { status } = await postInstance.create(
        {
          title: postTitle,
          description: postDescription,
        },
        address,
        users,
        hasUserJoined,
        id,
        setIsLoading,
        (post, cid) => {
          ipfsHash = cid;
          setTempPosts([
            {
              id: cid,
              ...post,
            },
            ...tempPosts,
          ]);
        }
      );

      if (status === 200) {
        clearInput();
        toast({
          title: `Post created successfully`,
          status: "success",
        });
        console.log(`Your greeting was posted 🎉`);
      } else {
        setIsLoading(false);
        console.log("Some error occurred, please try again!");
      }
    } catch (error) {
      console.error(error);
      console.log("Some error occurred, please try again!");
      setIsLoading(false);

      toast({
        title: `Add Post Failed, Try Again!`,
        description: error?.error?.toString() ?? error?.toString(),
        status: "error",
      });
    } finally {
      // setLoading.off()
      setTempPosts((prevPosts) => {
        const tempPostIndex = prevPosts.findIndex((t) => t.id === ipfsHash);
        if (tempPostIndex > -1) {
          const tempPostsCopy = [...prevPosts];
          tempPostsCopy.splice(tempPostIndex, 1);
          return tempPostsCopy;
        }
      });
    }
  };

  const checkIfUserHasJoined = () => {
    if (hasUserJoined) {
      return true;
    } else {
      toast({
        title: `Please Join the Community`,
        status: "error",
      });
      return false;
    }
  };

  const handleSortChange = (newSortBy: SortByOption) => {
    setSortBy(newSortBy);
  };
  const sortedData = useSortedVotes(tempPosts, data, sortBy);

  const voteForPost = async (postId, voteType: 0 | 1) => {
    if (!checkIfUserHasJoined()) return;
    const hasSufficientBalance = await checkUserBalance();
    if (!hasSufficientBalance) return;
    setIsLoading(true);

    try {
      postInstance
        .updatePostsVote(postInstance, postId, voteType, false)
        .then(() => setIsLoading(false));
      const { status } = await postInstance.vote(
        voteType,
        address,
        users,
        hasUserJoined,
        postId
      );

      if (status === 200) {
        toast({
          title: `${!voteType ? "Upvoted" : "Downvoted"} Successfully`,
          status: "success",
        });
        console.log(`${!voteType ? "Upvoted" : "Downvoted"} Successfully`);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: `${!voteType ? "Upvote" : "Downvote"} Failed, Try Again!`,
        description: error?.error?.toString() ?? error?.toString(),
        status: "error",
      });
      postInstance.updatePostsVote(postInstance, postId, voteType, true, true);
      setIsLoading(false);
    }
  };

  const communityHasBanner = React.useMemo(() => {
    return !!community?.banner;
  }, [community?.banner]);

  const clearInput = () => {
    setPostDescription(null);
    setPostTitle("");
    postEditorRef?.current?.clear();
  };

  if (isLoading) return <CommunitySkeleton />;

  return (
    <>
      <div>
        <MotionFlexBanner
          position={"relative"}
          height={{ base: 200, sm: 50 }}
          direction={{ base: "column-reverse", sm: "row" }}
        >
          <MotionHeading
            flexBasis={{ base: "100%", md: "40%", lg: "40%" }}
            as={"div"}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            height={"40px"}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              duration: 0.5,
            }}
            textAlign={{ base: "start" }}
            fontSize={{
              base: "1rem",
              sm: community?.name?.length > 20 ? "0.9rem" : "1.2rem",
              md: "1.3rem",
            }}
            color={useColorModeValue("primary.bg", "primary.50")}
            fontWeight={"bold"}
          >
            {community?.name}
          </MotionHeading>

          <Box
            hidden={!community?.logo}
            width={100}
            height={100}
            maxW={100}
            maxH={100}
            minWidth={100}
            minHeight={100}
            border={"1px solid"}
            borderRadius={"full"}
            position={"relative"}
            backgroundColor={{
              base: "transparent",
              sm: `${useColorModeValue("primary.50", "primary.bg")}`,
            }}
          >
            <Image
              position={"absolute"}
              top={0}
              left={0}
              right={0}
              bottom={0}
              margin={"auto"}
              zIndex={1}
              flexBasis={{ base: "100%", md: "20%", lg: "20%" }}
              hidden={!community?.logo}
              height={"75px !important"}
              width={"75px !important"}
              maxWidth={75}
              borderRadius={"full"}
              backgroundColor={"#292929"}
              src={`https://ipfs.io/ipfs/${community?.logo}`}
              alt={""}
            />
          </Box>

          <Box
            display={"flex"}
            flexBasis={{ base: "100%", md: "40%", lg: "40%" }}
            justifyContent={"end"}
          >
            <SortBy
              selectProps={{ bgColor: useColorModeValue("white", "gray.800") }}
              onSortChange={handleSortChange}
              targetType="posts"
            />
          </Box>
        </MotionFlexBanner>
        <br />
        {!hasUserJoined && community && <JoinCommunityButton community={community} />}
      </div>

      <VStack
        gridColumn={{ md: "span 2" }}
        zIndex={1}
        spacing={4}
        align="stretch"
        w={{ base: "100%", md: "100%", lg: "100%" }}
        justifyContent={"space-between"}
      >
        <NewPostForm
          id={id}
          postEditorRef={postEditorRef}
          postTitle={postTitle}
          setPostTitle={setPostTitle}
          postDescription={postDescription}
          setPostDescription={setPostDescription}
          isLoading={isLoading || !community?.name || isContextLoading}
          clearInput={clearInput}
          addPost={addPost}
        />
        {sortedData?.length > 0 ? (
          <PostList posts={sortedData} isLoading={isLoading} data={data} />
        ) : (
          <NoPosts />
        )}
      </VStack>
    </>
  );
}
const StyledText = ({ children, ...props }) => (
  <Text
    fontSize="small"
    display="inline"
    color={useColorModeValue("text.500", "text.200")}
    {...props}
  >
    {children}
  </Text>
);

const formVariants = {
  open: {
    opacity: 1,
    height: "fit-content",
    width: "100%",
    marginInline: "1rem",
    transition: {
      duration: 0.8,
    },
  },
  closed: {
    opacity: 0,
    height: 0, // You could use a small number instead of "unset" if needed
  },
  postOpen: {
    opacity: 1,
    height: "fit-content",
    width: "100%", // You could use a large number instead of "100%" if needed
    transition: {
      delay: 0.6,
      duration: 0.2,
    },
  },
  postClosed: {
    opacity: 0,
    height: 0,
  },
};

const NewPostForm = ({
  id,
  postEditorRef,
  postTitle,
  setPostTitle,
  postDescription,
  setPostDescription,
  isLoading,
  clearInput,
  addPost,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <HStack
        mt="3"
        alignItems={"center"}
        spacing={4}
        justifyContent={"space-between"}
        position={"relative"}
      >
        <motion.div
          layout
          initial={{ width: 30, visibility: "hidden" }}
          animate={{
            visibility: open ? "hidden" : "visible",
            width: open ? 30 : "fit-content",
          }}
          exit={{ width: 30, visibility: "hidden" }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
          style={{ alignSelf: "start", position: "relative" }}
        >
          <Button
            onClick={() => setOpen(true)}
            colorScheme="primary"
            variant="outline"
            size="sm"
            leftIcon={<AddIcon />}
          >
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -50, opacity: 0 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
                delay: 0.5,
              }}
            >
              <Text fontSize="lg">{t("newPost")}</Text>
            </motion.div>
          </Button>
        </motion.div>

        <motion.div
          animate={{ visibility: !open ? "hidden" : "visible" }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
          style={{ alignSelf: "start" }}
        >
          <CancelButton
            onClick={() => {
              setOpen(false);
              clearInput();
            }}
          >
            {t("button.cancel")}
          </CancelButton>
          &nbsp;
          <PrimaryButton
            isLoading={isLoading}
            onClick={addPost}
            disabled={!postTitle && !postDescription?.blocks?.length}
          >
            {isLoading ? <Spinner /> : t("button.post")}
          </PrimaryButton>
        </motion.div>
      </HStack>

      <motion.div
        initial="closed"
        animate={open ? "open" : "closed"}
        variants={formVariants}
        style={{ borderRadius: "10px", maxWidth: "650px", alignSelf: "center" }}
      >
        <>
          <>
            <VStack
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              p={4}
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: open ? 1 : 0 }}
                transition={{ duration: 0.2, delay: 0.2 }}
                style={{ width: "100%" }}
              >
                <Text fontSize="2xl" fontWeight="bold" textAlign="start">
                  {t("newPost")}
                </Text>
              </motion.div>

              <>
                <motion.div
                  style={{ width: "100%" }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: open ? 1 : 0 }}
                  transition={{ duration: 0.2, delay: 0.4 }}
                >
                  <Input
                    placeholder={t("placeholder.enterPostTitle")}
                    value={postTitle}
                    onChange={(e) => setPostTitle(e.target.value)}
                  />
                </motion.div>

                <motion.div
                  style={{ width: "100%", minHeight: "100px" }}
                  initial="postClosed"
                  animate={open ? "postOpen" : "postClosed"}
                  variants={formVariants}
                >
                  <Editor
                    holder={id}
                    ref={postEditorRef}
                    data={postDescription}
                    onChange={(val) => setPostDescription(val)}
                    placeholder={t("placeholder.enterPostContent")}
                  />
                </motion.div>
              </>
            </VStack>
          </>
        </>
      </motion.div>
    </>
  );
};

const PostList = ({ posts, isLoading, data }) => (
  <>
    {posts.map((p, i) => (
      <PostItem
        post={p}
        index={i}
        key={i}
        voteForPost={vote}
        isLoading={isLoading}
        data={data}
      />
    ))}
  </>
);

const PostItem = ({
  isLoading,
  voteForPost,
  data,
  post: { comments, createdAt, downvote, id, likes, title, upvote, views },
  index,
}) => {
  return (
    <motion.div key={index} variants={itemVariants}>
      <Flex
        direction="column"
        alignContent="flex-start"
        alignItems="flex-start"
        width={{ base: "100%", md: "100%", lg: "100%" }}
        key={typeof id === "object" ? Number(id?._hex ?? id?.hex) : id}
      >
        <Flex justify={"space-between"} width={"100%"}>
          {!postIsConfirmed(id) && (
            <StyledText
              fontWeight={"bold"}
              fontSize={"2xl"} // Increase font size
              lineHeight={1.5} // Increase line-height
            >
              {title}
            </StyledText>
          )}
          {postIsConfirmed(id) && (
            <Link to={`./posts/${id}`}>
              <StyledText
                fontWeight={"bold"}
                fontSize={"2xl"} // Increase font size
                lineHeight={1.5} // Increase line-height
              >
                {title}
              </StyledText>
            </Link>
          )}
          {!postIsConfirmed(id) && <Progress size="xs" isIndeterminate />}

          {postIsConfirmed(id) && (
            <Flex gap="3" flexDirection={"column"} alignItems={"flex-end"}>
              <Box display={"flex"} alignItems={"center"}>
                <VoteUpButton
                  isLoading={isLoading}
                  onClick={() => voteForPost(id, 0)}
                  voteCount={upvote}
                />
                <VoteDownButton
                  isLoading={isLoading}
                  onClick={() => voteForPost(id, 1)}
                  voteCount={downvote}
                />
              </Box>
              <StyledText marginBlock={"auto"}>
                🕛{" "}
                {formatDistanceToNow(new Date(createdAt), {
                  addSuffix: true,
                })}
              </StyledText>
            </Flex>
          )}
        </Flex>
        <Flex display={"none"} gap={5} marginBlockStart="1">
          <Box>
            <StyledText>👁️ {views}</StyledText>
          </Box>
          <Box>
            <StyledText>👍 {likes}</StyledText>
          </Box>
          <Box>
            <StyledText>💬 {comments}</StyledText>
          </Box>
        </Flex>
        {index < data?.length - 1 && (
          <Divider mt={"4"} mb={"4"} border="2px" orientation="horizontal" />
        )}
      </Flex>
    </motion.div>
  );
};
