import React, { ReactNode } from "react";
import { Tooltip, Box, Text } from "@chakra-ui/react";
import { TooltipProps } from "@chakra-ui/tooltip";

type PredefinedColor = "danger" | "primary" | "secondary" | "warning" | "info";

interface ToolTipProps {
  type: PredefinedColor;
  title?: ReactNode;
  message?: string;
  children: ReactNode;
  label?: ReactNode;
}

export const ToolTip: React.FC<ToolTipProps & TooltipProps> = ({
  type,
  title,
  message,
  children,
  label,
  ...props
}) => {
  const predefinedColors = {
    danger: "danger.500",
    primary: "primary.500",
    secondary: "blue.500",
    warning: "yellow.500",
    info: "info.500",
  };

  const background = predefinedColors[type] || "gray.500";

  return (
    <Tooltip
      color={"white"}
      hasArrow={true}
      background={background}
      label={
        label ||
        <Box>
          <Text>
            <b>{title}</b>
          </Text>
          {message && <Text>{message}</Text>}
        </Box>
      }
      // fontSize="md"
      openDelay={100} // Adjust this value for the press delay
      closeDelay={0} // Adjust this value for the release delay
      {...props}
      onTouchStart={(e) => {
        e.target.dispatchEvent(new Event("mouseEnter", { bubbles: true }));
      }}
      onTouchEnd={(e) => {
        e.target.dispatchEvent(new Event("mouseLeave", { bubbles: true }));
      }}
    >
      {children}
    </Tooltip>
  );
};
