import {mode} from "@chakra-ui/theme-tools";

const Select = {
    baseStyle: (props) => ({
        field: {
            borderColor: mode("primary.500", "primary.500")(props),
            _focus: {
                borderColor: "primary.500",
                boxShadow: "0 0 0 2px rgba(116, 105, 246, 0.6)",
            },
            _hover: {
                borderColor: "primary.600",
            },
        },
        option: {
            bg: mode("white", "gray.700")(props),
            color: mode("gray.900", "whiteAlpha.900")(props),
            _hover: {
                bg: mode("gray.100", "gray.600")(props),
            },
        },
        select: {
            color: mode("gray.700", "whiteAlpha.900")(props),
            _placeholder: {
                color: mode("gray.400", "whiteAlpha.400")(props),
            },
            bg: mode("white", "gray.700")(props),
        }
    }),
};

export default Select;
