import { useEffect } from "react";
import { useAppDispatch } from "../state/store";
import { useContract, useProvider } from "wagmi";
import { ForumContractAddress } from "../constant/const";
import ForumABI from "../constant/abi/Forum.json";
import { fetchCommunityData } from "../utils/communityUtils";
import { setCommunities } from "../state/slice";
import { useLoaderContext } from "../context/LoaderContext";
import { polygonMumbai } from "wagmi/chains";

export const useFetchCommunities = (loadOnInit = true) => {
  const dispatch = useAppDispatch();
  const { setIsLoading } = useLoaderContext();

  const provider = useProvider({ chainId: polygonMumbai.id });
  const forumContract = useContract({
    address: ForumContractAddress,
    abi: ForumABI.abi,
    signerOrProvider: provider,
  });
  const fetchCommunities = async () => {
    setIsLoading(true);

    try {
      const groups = await forumContract.queryFilter(
        forumContract.filters.NewGroupCreated()
      );

      const communityPromises = groups.reverse().map((group) =>
        fetchCommunityData({
          group,
          forumContract,
          provider,
        })
      );

      const communityData = await Promise.allSettled(communityPromises);

      const fulfilledCommunities = communityData
        .filter((community) => community.status === "fulfilled")
        .map((community: PromiseFulfilledResult<any>) => community.value)
        .filter((community) => community !== null);

      console.log("fulfilledCommunities", fulfilledCommunities);
      dispatch(setCommunities(fulfilledCommunities));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (loadOnInit) fetchCommunities();
  }, [dispatch, forumContract, provider]);
  return fetchCommunities;
};
