// theme/components/Card.ts
import { ThemeComponents } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const Card: ThemeComponents["Cards"] = {
  parts: ["body", "header", "footer", "container"],

  baseStyle: (props) => ({
    container: {
      bg: mode("white", "gray.800")(props),
      color: mode("gray.800", "white")(props),
      boxShadow: mode("lg", "dark-lg")(props),
      borderColor: mode("gray.200", "gray.900")(props),
      _hover: {
        boxShadow: mode("xl", "light-xl")(props),
      },
    },

    [".community-card-header"]: {
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: mode("primary.500", "primary.500")(props),
    },
    body: {
      borderColor: mode("primary.500", "white")(props),
      borderBottomWidth: 0,
      borderBottomRadius: 0,
    },
    header: {
      [".community-card-banner"]: {
        fontWeight: "bold",
        fontSize: "2xl",
        borderStyle: "solid",
        color: mode("white", "white")(props),
        borderWidth: 1,
        borderBottomWidth: 0,
        backgroundColor: mode("primary.500", "primary.500")(props),
        borderColor: mode("primary.500", "white")(props),
      },
      button: {
        bg: mode("primary.500", "primary.500")(props),
        borderRadius: "base",
        borderColor: mode("primary.500", "primary.bg")(props),
        borderStyle: "solid",
        fontWeight: "bold",
        borderWidth: "1px",
        color: mode("white", "white")(props),
      },
    },
    footer: {
      borderColor: mode("primary.500", "white")(props),
      borderWidth: 1,
      borderTopWidth: 0,
      display: "flex",
      padding: 2,
      borderBottomRadius: "base",
      ['button.joined']:{
        opacity: 0.5,
      },
      button: {
        // bg: mode("primary.500", "primary.500")(props),
        borderRadius: "base",
        borderColor: mode("primary.500", "white")(props),
        borderStyle: "solid",
        fontWeight: "bold",
        borderWidth: "1px",
      },
    },
  }),
};

export default Card;
