import { IconButton, useColorMode } from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";
import { ToolTip } from "./HOC/ToolTip";
import React from "react";

const ThemeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <ToolTip
      type={"primary"}
      title={`Switch to ${colorMode === "light" ? "dark" : "light"} `}
    >
      <IconButton
        variant={"outline"}
        colorScheme={"primary"}
        aria-label="Toggle theme"
        icon={colorMode === "light" ? <FaMoon /> : <FaSun />}
        onClick={toggleColorMode}
      />
    </ToolTip>
  );
};

export default ThemeToggle;
