import { Button, ButtonGroup, useColorModeValue } from "@chakra-ui/react";
import { ToolTip } from "../HOC/ToolTip";
import React from "react";

const FilterButtons = ({
  filters,
  onFilter,
  activeFilter,
  displayIcon = true,
  displayName = true,
}) => {
  return (
    <ButtonGroup spacing={0}  borderRadius={'md'} textAlign={"center"}>
      {filters.map((filter, index) => {
        const isActive = activeFilter.includes(filter.name);

        return (
          <ToolTip
            key={filter.name}
            type={"primary"}
            title={filter.name}
            fontSize={8}
          >
            <Button
              onClick={() => onFilter(filter.name)}
              borderRightRadius={index === filters.length - 1 ? "sm" : "none"}
              borderBlock={'1px'}
              borderLeft={index === 0 ? "1px" : "none"}
              borderLeftRadius={index === 0 ? "sm" : "none"}
              borderRight={index === filters.length - 1 ? "1px" : "none"}
              colorScheme={isActive ? "primary" : "gray"}
              isActive={isActive}
              size={"md"}
              _focus={{ boxShadow: "none" }}
            >
              {displayIcon && filter.icon}
              {displayName && <>&nbsp;{filter.name} </>}
            </Button>
          </ToolTip>
        );
      })}
    </ButtonGroup>
  );
};

export default FilterButtons;
