//./components/Editor
import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef } from "react";
import EditorJS, { OutputData } from "@editorjs/editorjs";
import { EDITOR_TOOLS } from "../constant/editor-tool";
//props
type Props = {
    data?: OutputData;
    onChange(val: OutputData): void;
    holder: string;
    placeholder?: string;
    readOnly?: boolean
    divProps?: any;
};
const EditorBlock = forwardRef(({ data, onChange, holder, placeholder,divProps={}, readOnly = false }: Props, reference) => {
    //add a reference to editor
    const ref = useRef<EditorJS>();
    //initialize editorjs

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(reference, () => ({

        clear() {
            ref?.current?.clear();
        },
        reRender() {
            if (ref?.current?.render) {
                ref?.current?.render(data);
            }
        }
    }));

    useEffect(() => {
        ref?.current?.readOnly.toggle();;

        if (!readOnly) {
            // ref?.current?.render(data);
            setTimeout(() => {
                if (ref?.current?.focus) ref.current.focus(true);
            }, 200)

        } else {
            setTimeout(() => {
                if (ref?.current?.render && data?.blocks) ref.current.render(data);
            }, 0)
        }
        if (!data?.blocks) {
            setTimeout(() => {
                if (ref?.current?.clear) ref.current.clear();
            }, 200)
        }
    }, [readOnly])

    useEffect(() => {
        //initialize editor if we don't have a reference
        if (!ref.current) {
            const editor = new EditorJS({
                holder: holder,
                hideToolbar: false,
                minHeight: 0,
                autofocus: true,
                inlineToolbar: true,
                tools: EDITOR_TOOLS,
                placeholder,
                data,
                readOnly,
                async onChange(api, event) {
                    const data = await api.saver.save();
                    onChange(data);
                },

                onReady() {
                    const links = document.getElementsByTagName('a');
                    for (let i = 0; i < links.length; i++) {
                        const link = links[i]
                        link.setAttribute('target', '_blank')
                        link.setAttribute('rel', 'noopener')
                        link.setAttribute('aria-label', 'External link (opens in new tab)')
                    }
                    // If readonly remove extra margin applied to below mentioned class
                    if (readOnly) {
                        const el = document.getElementById(holder)
                        const codeEditorRedactor: any = el.getElementsByClassName('codex-editor__redactor')
                        codeEditorRedactor[0].style.marginRight = '0'
                    }
                },
            });
            ref.current = editor;
        }
        //add a return function handle cleanup
        return () => {
            if (ref.current && ref.current.destroy) {
                ref.current.destroy();
            }
        };
    }, []);

    return <div className={'editor'} id={holder} {...divProps} />;
});
export default memo(EditorBlock);
