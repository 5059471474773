/**
 * @module @semaphore-protocol/identity
 * @version 2.5.0
 * @file A library to create Semaphore identities.
 * @copyright Ethereum Foundation 2022
 * @license MIT
 * @see [Github]{@link https://github.com/semaphore-protocol/semaphore/tree/main/packages/identity}
*/
import { BigNumber } from '@ethersproject/bignumber';
import poseidon from 'poseidon-lite';
import { randomBytes } from '@ethersproject/random';
import { sha256 as sha256$1 } from '@ethersproject/sha2';
import { toUtf8Bytes } from '@ethersproject/strings';

function checkParameter(value, name, type) {
    if (typeof value !== type) {
        throw new TypeError("Parameter '".concat(name, "' is not a ").concat(type));
    }
}

/**
 * Returns an hexadecimal sha256 hash of the message passed as parameter.
 * @param message The string to hash.
 * @returns The hexadecimal hash of the message.
 */
function sha256(message) {
    var hash = sha256$1(toUtf8Bytes(message));
    return hash;
}
/**
 * Generates a random big number.
 * @param numberOfBytes The number of bytes of the number.
 * @returns The generated random number.
 */
function genRandomNumber(numberOfBytes) {
    if (numberOfBytes === void 0) { numberOfBytes = 31; }
    return BigNumber.from(randomBytes(numberOfBytes)).toBigInt();
}
/**
 * Checks if a string is a JSON.
 * @param jsonString The JSON string.
 * @returns True or false.
 */
function isJsonArray(jsonString) {
    try {
        return Array.isArray(JSON.parse(jsonString));
    }
    catch (error) {
        return false;
    }
}

var Identity = /** @class */ (function () {
    /**
     * Initializes the class attributes based on the strategy passed as parameter.
     * @param identityOrMessage Additional data needed to create identity for given strategy.
     */
    function Identity(identityOrMessage) {
        if (identityOrMessage === undefined) {
            this._trapdoor = genRandomNumber();
            this._nullifier = genRandomNumber();
            return;
        }
        checkParameter(identityOrMessage, "identityOrMessage", "string");
        if (!isJsonArray(identityOrMessage)) {
            var messageHash = sha256(identityOrMessage).slice(2);
            this._trapdoor = BigNumber.from(sha256("".concat(messageHash, "identity_trapdoor"))).toBigInt();
            this._nullifier = BigNumber.from(sha256("".concat(messageHash, "identity_nullifier"))).toBigInt();
            return;
        }
        var _a = JSON.parse(identityOrMessage), trapdoor = _a[0], nullifier = _a[1];
        this._trapdoor = BigNumber.from("0x".concat(trapdoor)).toBigInt();
        this._nullifier = BigNumber.from("0x".concat(nullifier)).toBigInt();
    }
    /**
     * Returns the identity trapdoor.
     * @returns The identity trapdoor.
     */
    Identity.prototype.getTrapdoor = function () {
        return this._trapdoor;
    };
    /**
     * Returns the identity nullifier.
     * @returns The identity nullifier.
     */
    Identity.prototype.getNullifier = function () {
        return this._nullifier;
    };
    /**
     * Generates the identity commitment from trapdoor and nullifier.
     * @returns identity commitment
     */
    Identity.prototype.generateCommitment = function () {
        return poseidon([poseidon([this._nullifier, this._trapdoor])]);
    };
    /**
     * Returns a JSON string with trapdoor and nullifier. It can be used
     * to export the identity and reuse it later.
     * @returns The string representation of the identity.
     */
    Identity.prototype.toString = function () {
        return JSON.stringify([this._trapdoor.toString(16), this._nullifier.toString(16)]);
    };
    return Identity;
}());

export { Identity };
