import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useAccount, useContractRead, useContractWrite } from "wagmi";
import { ForumContractAddress } from "../constant/const";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  IconButton,
  Input,
  Skeleton,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useCheckIfUserIsAdminOrModerator } from "../hooks/useCheckIfUserIsAdminOrModerator";
import { useFetchAdminsAndModerators } from "../hooks/useFetchAdminsAndModerators";
import ForumABI from "../constant/abi/Forum.json";
import { useLoaderContext } from "../context/LoaderContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Access: React.FC = () => {
  const { address } = useAccount();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isAdmin, isModerator, fetchIsAdmin } =
    useCheckIfUserIsAdminOrModerator(address);
  const {
    isFetching,
    isLoading,
    admins,
    moderators,
    fetchAdmins,
    fetchModerators,
  } = useFetchAdminsAndModerators();
  const { setIsLoading } = useLoaderContext();

  useEffect(() => {
    console.log(address);
    if (address) {
      fetchIsAdmin();
      fetchAdmins();
      fetchModerators();
    } else {
      navigate("/");
    }
  }, [address]);

  /** remove admin */
  const { write: writeRemoveAdmin } = useContractWrite({
    address: ForumContractAddress as `0x${string}`,
    abi: ForumABI.abi,
    functionName: "removeAdmin",
    mode: "recklesslyUnprepared",
    onSettled: (data, error) => {
      setIsLoading(false);
    },
    onSuccess: async (data, variables) => {
      try {
        await data.wait();
        fetchAdmins();
        fetchIsAdmin();
      } catch (error) {}
    },
  });

  /** remove moderator */
  const { write: writeRemoveModerator } = useContractWrite({
    address: ForumContractAddress as `0x${string}`,
    abi: ForumABI.abi,
    functionName: "removeModerators",
    mode: "recklesslyUnprepared",
    onSettled: (data, error) => {
      setIsLoading(false);
    },
    onSuccess: async (data, variables) => {
      try {
        await data.wait();
        fetchModerators();
      } catch (error) {}
    },
  });

  /** add admin */
  const { write: writeAddAdmin } = useContractWrite({
    address: ForumContractAddress as `0x${string}`,
    abi: ForumABI.abi,
    functionName: "addAdmins",
    mode: "recklesslyUnprepared",
    onSettled: (data, error) => {
      setIsLoading(false);
    },
    onSuccess: async (data, variables) => {
      try {
        await data.wait();
        fetchAdmins();
      } catch (error) {}
    },
  });

  /** add moderator */
  const { write: writeAddModerator } = useContractWrite({
    address: ForumContractAddress as `0x${string}`,
    abi: ForumABI.abi,
    functionName: "addModerators",
    mode: "recklesslyUnprepared",
    onSettled: (data, error) => {
      setIsLoading(false);
    },
    onSuccess: async (data, variables) => {
      try {
        await data.wait();
        fetchModerators();
      } catch (error) {}
    },
  });

  const onAddAdmin = (value) => {
    setIsLoading(true);
    writeAddAdmin({
      recklesslySetUnpreparedArgs: [[value]],
    });
  };

  const onAddModerator = (value) => {
    setIsLoading(true);
    writeAddModerator({
      recklesslySetUnpreparedArgs: [[value]],
    });
  };

  const onRemoveAdmin = (value) => {
    setIsLoading(true);
    writeRemoveAdmin({
      recklesslySetUnpreparedArgs: [value],
    });
  };

  const onRemoveModerator = (value) => {
    setIsLoading(true);
    writeRemoveModerator({
      recklesslySetUnpreparedArgs: [[value]],
    });
  };

  const AddressInput = ({ placeholder, onSubmit }) => {
    const [value, setValue] = useState("");
    const patternError = value && !/^0x[a-fA-F0-9]{40}$/g.test(value);
    return (
      <Flex gap={3}>
        <FormControl isInvalid={patternError}>
          <Input
            type="text"
            isInvalid={patternError}
            focusBorderColor={patternError ? "danger.300" : ""}
            errorBorderColor="danger.300"
            size={"md"}
            placeholder={placeholder}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            variant={"outline"}
          ></Input>
          {patternError && (
            <FormHelperText>
              {t("formErrors.enterValidAddress")}
            </FormHelperText>
          )}
        </FormControl>
        <Button
          onClick={() => onSubmit(value)}
          isDisabled={!value || patternError}
          size={"md"}
          variant={"outline"}
        >
          Add
        </Button>
      </Flex>
    );
  };

  const TableWrapper = ({ items, canRemove, onRemove }) => {
    return (
      <TableContainer>
        <Table variant="simple" size={"md"}>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Address</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          {isFetching || isLoading ? (
            <Tbody>
              <Tr>
                <Td>
                  <Skeleton display={"list-item"}></Skeleton>
                </Td>
                <Td>
                  <Skeleton display={"list-item"}></Skeleton>
                </Td>
                <Td>
                  <Skeleton display={"list-item"}></Skeleton>
                </Td>
              </Tr>
            </Tbody>
          ) : (
            <Tbody>
              {items?.map((a, i) => (
                <Tr key={i} fontSize={"14px"}>
                  <Td>{i + 1}</Td>
                  <Td py={"8px"}>{a}</Td>
                  <Td py={"8px"} textAlign={"center"}>
                    {canRemove && (
                      <IconButton
                        onClick={() => onRemove(a)}
                        size={"sm"}
                        aria-label="remove admin"
                        icon={<DeleteIcon />}
                      ></IconButton>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
      </TableContainer>
    );
  };
  return (
    <Flex gap={5} direction={"column"}>
      <Heading textAlign={"left"} color={"primary.500"}>
        Admins
      </Heading>
      <AddressInput
        placeholder={"Admin Address"}
        onSubmit={(v) => onAddAdmin(v)}
      ></AddressInput>
      <TableWrapper
        items={admins}
        canRemove={isAdmin}
        onRemove={(v) => onRemoveAdmin(v)}
      ></TableWrapper>

      <Heading textAlign={"left"} color={"primary.500"}>
        Moderators
      </Heading>
      <AddressInput
        placeholder={"Moderator Address"}
        onSubmit={(v) => onAddModerator(v)}
      ></AddressInput>
      <TableWrapper
        items={moderators}
        canRemove={isAdmin || isModerator}
        onRemove={(v) => onRemoveModerator(v)}
      ></TableWrapper>
    </Flex>
  );
};

export default Access;
