import React, {
  ChangeEventHandler,
  SelectHTMLAttributes,
  useState,
} from "react";
import {
  Select,
  VStack,
  Text,
  Box,
  HStack,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiFilter } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { StackProps } from "@chakra-ui/layout";
import { SelectProps } from "@chakra-ui/select";

export type SortByOption =
  | "highest"
  | "lowest"
  | "controversial"
  | "newest"
  | "oldest";

interface SortByProps {
  onSortChange: (sortBy: SortByOption) => void;
  targetType: "comments" | "posts";
  selectProps?: SelectProps;
}

const SortBy: React.FC<SortByProps & StackProps> = ({
  onSortChange,
  targetType,
  selectProps,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState<SortByOption>("highest");
  const { t } = useTranslation();

  const handleChange = (e) => {
    const newOption = e.target.value as SortByOption;
    setSelectedOption(newOption);
    onSortChange(newOption);
  };

  return (
    <HStack spacing={2} {...props}>
      <Select
        value={selectedOption}
        borderRadius="md"
        boxShadow="sm"
        icon={<Icon as={FiFilter} />}
        iconSize="sm"
        _hover={{ boxShadow: "md" }}
        iconColor={useColorModeValue("gray.500", "gray.200")}
        {...selectProps}
        onChange={handleChange}
      >
        <option value="highest">{t("filter.highestUpVotes")}</option>
        <option value="lowest">{t("filter.lowestUpVotes")}</option>
        <option value="controversial">{t("filter.controversial")}</option>
        <option value="newest">{t("filter.newest")}</option>
        <option value="oldest">{t("filter.oldest")}</option>
      </Select>
    </HStack>
  );
};

export default SortBy;
