import { Box, Image, useColorModeValue } from "@chakra-ui/react";
import { Outlet, useParams } from "react-router-dom";
import Footer from "./Footer";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useAppSelector } from "../state/store";
import { selectCommunityById } from "../state/slice";
import { Breadcrumbs } from "./Breadcrumbs";
import EditGroupModal from "./EditGroupModal";

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

export function PrimaryLayout() {
  const { id } = useParams();

  const community =
    useAppSelector((state) => selectCommunityById(state, +id)) || null;

  const ref = React.useRef(null);

  useEffect(() => {
    // scroll to top
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  }, [ref, id]);

  return (
    <motion.div
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      style={{ width: "100%" }}
      ref={ref}
    >
      <Breadcrumbs />

      {community?.banner && (
        <Box
          display={"flex"}
          zIndex={0}
          flexDirection={"column"}
          position={"relative"}
          alignItems={"stretch"}
          mx={{ base: 2, sm: 4, md: 20 }}
          border={"1px solid"}
          borderColor={useColorModeValue("primary.bg", "primary.50")}
          borderRadius={"md"}
          mb={0}
        >
          <EditGroupModal community={community} hidden={false} />
          <Image
            src={`https://ipfs.io/ipfs/${community?.banner}`}
            alt=""
            borderRadius={"md"}
            style={{
              border: 0,
              width: "100%",
              maxWidth: "100%",
              maxHeight: "300px",
              height: "100%",
              objectFit: "cover",
              zIndex: 1,
            }}
          />
        </Box>
      )}

      <Box display={"flex"} flexDirection={"column"}>
        <Box
          minHeight={"100vh"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"stretch"}
          px={{ base: 2, sm: 4, md: 20 }}
          gap={{ base: 4, sm: 8, md: 20 }}
          mt={{
            base: community?.banner ? 0 : 4,
            sm: community?.banner ? 0 : 8,
            md: community?.banner ? 0 : 20,
          }}
        >
          <Outlet />
        </Box>
        <Footer />
      </Box>
    </motion.div>
  );
}
