import React from "react";
import { Button } from "@chakra-ui/react";
import { ButtonProps } from "@chakra-ui/button";

export function CancelButton({
  onClick,
  children,
  ...rest
}: {
  onClick: () => void;
  children?: React.ReactNode;
} & ButtonProps) {
  return (
    <Button
      size={"sm"}
      className={"fade-in"}
      variant={"outline"}
      onClick={onClick}
      _hover={{ color: "primary.700" }}
      {...rest}
    >
      {children}
    </Button>
  );
}
