import { mode } from "@chakra-ui/theme-tools";
import { ThemeComponents } from "@chakra-ui/react";

const Breadcrumb: ThemeComponents["Breadcrumb"] = {
  parts: ["item", "link"],
  baseStyle: (props) => ({
    item: {
      color: mode("gray.500", "gray.400")(props),
      _hover: {
        color: mode("primary.500", "primary.300")(props),
      },
      _current: {
        fontWeight: "bold",
        color: mode("primary.500", "primary.300")(props),
      },
    },
    link: {
      color: mode("primary.500", "primary.400")(props),
      _hover: {
        color: mode("primary.500", "primary.300")(props),
      },
      _current: {
        fontWeight: "bold",
        color: mode("primary.500", "primary.300")(props),
      },
    },
  }),
};

export default Breadcrumb;
