import {mode} from "@chakra-ui/theme-tools";

const Radio = {
    baseStyle: (props) => ({
        control: {
            borderColor: mode("primary.500", "primary.500")(props),
            _checked: {
                bg: "primary.500",
                borderColor: "primary.500",
            },
            _focus: {
                boxShadow: "0 0 0 2px rgba(116, 105, 246, 0.6)",
            },
            _hover: {
                borderColor: "primary.600",
            },
        },
    }),
};


export default Radio;
