import { Image, useColorMode } from "@chakra-ui/react";
import logo from "../assets/sacred-logos-wordmark.svg";
import logolight from "../assets/sacred-logos-wordmark-light.svg";
import React from "react";

export const Logo = (props: any) => {
  const { colorMode } = useColorMode();
  return (
    <Image
      _hover={{ opacity: 0.8 }}
      src={colorMode === "dark" ? logolight : logo}
      width={200}
    />
  );
};
