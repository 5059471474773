import React, { useState } from "react";
import {
  Box,
  CardHeader,
  Image,
  Skeleton,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { useLocalCommunity } from "./CommunityCard";
import { CardHeaderProps } from "@chakra-ui/card";
import { ImageProps } from "@chakra-ui/image";

export const CommunityCardHeader = ({
  imageProps,
  ...props
}: { imageProps?: ImageProps } & CardHeaderProps) => {
  const community = useLocalCommunity();
  const [bannerLoaded, setBannerLoaded] = useState(false);
  const [bannerError, setBannerError] = useState(false);

  const handleBannerLoad = () => {
    setBannerLoaded(true);
  };

  const handleBannerError = () => {
    setBannerError(true);
  };

  const bannerSrc =
    community?.banner && !bannerError
      ? `https://ipfs.io/ipfs/${community.banner}`
      : undefined;

  return (
    <CardHeader className={"community-card-header"} {...props}>
      {bannerSrc ? (
        <Image
          className={"community-card-banner"}
          borderRadius="md"
          borderBottomRadius={0}
          width="100%"

          src={bannerSrc}
          alt={community?.name}
          onLoad={handleBannerLoad}
          onError={handleBannerError}
          {...imageProps}
        />
      ) : (
        <Box
          className={"community-card-banner"}
          border={"1px solid"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius="md"
          borderBottomRadius={0}
          borderBottom={0}
        >
          {community?.name}
        </Box>
      )}

      <Image
        border={`1px solid ${useColorModeValue("primary.500", "primary.500")}`}
        hidden={!community?.logo}
        className={"community-card-logo"}
        backgroundColor={useColorModeValue("#f5f8fa", "gray.800")}
        src={`https://ipfs.io/ipfs/${community.logo}`}
        alt={""}
      />
    </CardHeader>
  );
};
