const colors = {
  primary: {
    "50": "#f9f5fe",
    "100": "#e7d9fd",
    "200": "#d5bcfb",
    "300": "#9557f1",
    "400": "#a873f7",
    "500": "#8244e1",
    "600": "#7443bc",
    "700": "#5f379a",
    "800": "#4e2d7e",
    "900": "#39215d", // darker color for better contrast in light mode
    "bg": "#1A1A1AFF",
  },
  secondary: {
    100: "#e6f7ff",
    300: "#99e0ff",
    500: "#36c3ff",
    700: "#1f7f99",
    900: "#12455f",
  },
  gray: {
    50: "#f9f9f9",
    100: "#f5f5f5",
    150: "#f0f0f0",
    200: "#e5e5e5",
    250: "#d9d9d9",
    300: "#c4c4c4",
    350: "#b3b3b3",
    400: "#9a9a9a",
    450: "#8a8a8a",
    500: "#707070",
    550: "#616161",
    600: "#545454",
    650: "#464646",
    700: "#3d3d3d",
    750: "#333333",
    800: "#292929",
    850: "#1f1f1f",
    900: "#1a1a1a",
    950: "#121212",
  },
  success: {
    100: "#e6ffed",
    300: "#99f2c1",
    500: "#2bd395",
    700: "#1a785e",
    900: "#0d453e",
  },
  warning: {
    100: "#fff8e6",
    300: "#ffdf99",
    500: "#ffc61a",
    700: "#997a0d",
    900: "#5f4d08",
  },
  error: {
    100: "#ffe6e6",
    300: "#ff9999",
    500: "#ff3636",
    700: "#991a1a",
    900: "#5f0d0d",
  },
  black: "#0e1012",

  purple: {
    "50": "#f9f5fe",
    "100": "#e7d9fd",
    "200": "#d5bcfb",
    "300": "#bb92f8",
    "400": "#a873f7",
    "500": "#8a51e1",
    "600": "#7443bc",
    "700": "#5f379a",
    "800": "#4e2d7e",
    "900": "#39215d",
  },
  pink: {
    "50": "#fef5f9",
    "100": "#fdd7e7",
    "200": "#fbb6d3",
    "300": "#f885b7",
    "400": "#f55d9f",
    "500": "#ce4a83",
    "600": "#b03f70",
    "700": "#90345b",
    "800": "#702847",
    "900": "#521e34",
  },
  red: {
    "50": "#fef5f5",
    "100": "#fcd8d5",
    "200": "#fab4af",
    "300": "#f7857d",
    "400": "#f5665c",
    "500": "#d2544b",
    "600": "#b14740",
    "700": "#8e3933",
    "800": "#79302b",
    "900": "#57231f",
  },
  orange: {
    "50": "#fefaf4",
    "100": "#fcebd3",
    "200": "#f9d2a0",
    "300": "#f2af57",
    "400": "#d1974b",
    "500": "#b48241",
    "600": "#986e36",
    "700": "#79572b",
    "800": "#5f4522",
    "900": "#4e381c",
  },
  yellow: {
    "50": "#fffefa",
    "100": "#fdfade",
    "200": "#f9ef9b",
    "300": "#f1e257",
    "400": "#ddce4f",
    "500": "#b6aa41",
    "600": "#918834",
    "700": "#716a29",
    "800": "#554f1e",
    "900": "#464119",
  },
  green: {
    "50": "#f1fef9",
    "100": "#affada",
    "200": "#56efad",
    "300": "#4dd59a",
    "400": "#43bb87",
    "500": "#3aa074",
    "600": "#308560",
    "700": "#25674a",
    "800": "#1e543d",
    "900": "#194532",
  },
  teal: {
    "50": "#edfdfe",
    "100": "#aaf5fa",
    "200": "#57e8f2",
    "300": "#4ecfd8",
    "400": "#42b0b8",
    "500": "#38969c",
    "600": "#2d797f",
    "700": "#235e62",
    "800": "#1d4f52",
    "900": "#184143",
  },
  cyan: {
    "50": "#f2fbfe",
    "100": "#c9f0fc",
    "200": "#afe8fa",
    "300": "#91e0f8",
    "400": "#52c2e4",
    "500": "#4bb2d2",
    "600": "#44a0bd",
    "700": "#38859c",
    "800": "#2e6d80",
    "900": "#245463",
  },
  blue: {
    "50": "#f1f6fe",
    "100": "#cae0fc",
    "200": "#a3c8f9",
    "300": "#77aef7",
    "400": "#5495ea",
    "500": "#4880c9",
    "600": "#3c6ba8",
    "700": "#2e5281",
    "800": "#264369",
    "900": "#1f3756",
  },

  info: {
    "50": "#f2fbfe",
    "100": "#c9f0fc",
    "200": "#afe8fa",
    "300": "#91e0f8",
    "400": "#52c2e4",
    "500": "#4bb2d2",
    "600": "#44a0bd",
    "700": "#38859c",
    "800": "#2e6d80",
    "900": "#245463",
  },
  danger: {
    "50": "#fef5f5",
    "100": "#fcd8d5",
    "200": "#fab4af",
    "300": "#f7857d",
    "400": "#f5665c",
    "500": "#d2544b",
    "600": "#b14740",
    "700": "#8e3933",
    "800": "#79302b",
    "900": "#57231f",
  },

  //
  // primary: {
  //     50: "#f7f6fe",
  //     100: "#dfddfd",
  //     200: "#c4bffb",
  //     300: "#a29bf9",
  //     400: "#8f86f8",
  //     500: "#7469f6",
  //     600: "#5e53e6",
  //     700: "#4b42b9",
  //     800: "#40389c",
  //     900: "#2e2971",
  // },
  // secondary: {
  //     50: "#f9fafa",
  //     100: "#f1f1f2",
  //     200: "#e7e7e8",
  //     300: "#d3d4d5",
  //     400: "#abadaf",
  //     500: "#7d7f83",
  //     600: "#52555a",
  //     700: "#33373d",
  //     800: "#1d2025",
  //     900: "#171a1d",
  // },
  accent: {
    50: "#f2fbfe",
    100: "#c9f0fc",
    200: "#afe8fa",
    300: "#91e0f8",
    400: "#52c2e4",
    500: "#4bb2d2",
    600: "#44a0bd",
    700: "#38859c",
    800: "#2e6d80",
    900: "#245463",
  },
  neutral: {
    50: "#f1f6fe",
    100: "#cae0fc",
    200: "#a3c8f9",
    300: "#77aef7",
    400: "#5495ea",
    500: "#4880c9",
    600: "#3c6ba8",
    700: "#2e5281",
    800: "#264369",
    900: "#1f3756",
  },

  light: {
    text: "#2D3748",
    background: "#F7FAFC",
  },
  dark: {
    text: "#F7FAFC",
    background: "#2D3748",
  },

  background: {
    light: "#ffffff",
    dark: "#0e1012",
  },
  text: {
    light: "#0e1012",
    dark: "#ffffff",
  },
  border: {
    light: "#2e2e2e",
    dark: "#2e2e2e",
  },
};

export default colors;
