import {mode} from "@chakra-ui/theme-tools";
import {ThemeComponents} from "@chakra-ui/react";

const Accordion: ThemeComponents["Accordion"] = {


    baseStyle: (props) => ({
        button: {
            fontWeight: "normal",
            color: mode("gray.800", "white")(props),
            _hover: {
                bg: mode("gray.100", "gray.600")(props),
            },
            _expanded: {
                fontWeight: "semibold",
                color: mode("primary.500", "primary.300")(props),
            },
        },
        panel: {
            bg: mode("gray.50", "gray.700")(props),
            borderRadius: "md",
        },
    }),
};


export default Accordion;
