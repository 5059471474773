import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const NoPosts = () => {
  const { t } = useTranslation();

  return (
    <Box py={4} borderRadius="md" borderWidth={1}>
      <Text fontSize="xl" fontWeight="bold">
        {t("noPostsFound")}
      </Text>
    </Box>
  );
};
