import React from "react";
import { ToolTip } from "./HOC/ToolTip";
import { useTranslation } from "react-i18next";
import { IconButton } from "@chakra-ui/react";
import { FiDelete } from "react-icons/fi";
import { DeleteIcon } from "@chakra-ui/icons";
import { MdOutlineDelete } from "react-icons/md";

interface RemoveGroupProps {
  onClick: () => void;
  hidden: boolean
}

const RemoveGroup: React.FC<RemoveGroupProps> = ({ onClick, hidden }) => {
  const { t } = useTranslation();

  return (
    <>
      <ToolTip
        type={"primary"}
        title={t("toolTip.removeCommunity.title")}
        message={t("toolTip.removeCommunity.message")}
      >
        <IconButton
          id={"edit-community-button"}
          position={"absolute"}
          right={50}

          hidden={hidden}
          variant={"ghost"}
          _hover={{ bg: "#8F15AB7F", color: "white" }}
          style={{ zIndex: 1 }}
          onClick={() => onClick()}
          icon={<MdOutlineDelete />}
          aria-label={"edit community"}
        />
      </ToolTip>
    </>
  );
};

export default RemoveGroup;
