import { Community } from "../lib/model";
import { ethers } from "ethers";
import { useCallback, useState } from "react";
import { validateUserBalance, ValidationResult } from "./validateUserBalance";
import { useToast } from "@chakra-ui/react";

export const useValidateUserBalance = (
  community: Community | undefined,
  address: string,
  provider: ethers.providers.Provider
) => {
  const [validationResult, setValidationResult] =
    useState<ValidationResult | null>(null);
  const toast = useToast();

  const checkUserBalance = useCallback(async () => {
    if (!community) return false;

    if (!address) {
        toast({
            title: `No wallet connected`,
            description: `Please connect your wallet to continue`,
            variant: "solid",
            status: "loading",
            duration: 7000,
            isClosable: true,
        });
        return false;
    }

    const { hasSufficientBalance, toastMessage } = await validateUserBalance(
      community,
      address,
    );

    if (!hasSufficientBalance) {
      toast({
        title: `Insufficient Balance`,
        description: toastMessage,
        variant: "solid",
        status: "error",
        duration: 7000,
        isClosable: true,
      });
    }

    setValidationResult({ hasSufficientBalance, toastMessage });
    return hasSufficientBalance;
  }, [community, address, toast]);

  return { validationResult, checkUserBalance };
};
