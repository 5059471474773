// theme/components/Button.js
import { useColorModeValue } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const Button = {
  variants: {
    outline: (props) => ({

      // no parent with role="group"
      ['&:not([role="group"])']: {
        color: mode("primary.500", "white")(props),
        bg: mode("white", "primary.bg")(props),
        _hover: {
          bg: mode("primary.500", "primary.300")(props),
          color: mode("white", "white")(props),
          borderColor: mode("primary.500", "primary.300")(props),
        },
        _active: {
          // borderColor: mode("gray.200", "primary.300")(props),
          opacity: 1,
          bg: mode("primary.300", "primary.500")(props),
          color: mode("white", "white")(props),
        },

      }



    }),

    solid: (props) => ({
      borderColor: mode("primary.500", "primary.300")(props),
      color: mode("white", "white")(props),
      bg: mode("primary.500", "primary.500")(props),

      _hover: {
        bg: mode("primary.600", "primary.400")(props),
        color: mode("white", "white")(props),
      },

      _active: {
        bg: mode("primary.700", "primary.500")(props),
        color: mode("white", "white")(props),
      },

      _focus: {
        boxShadow: mode(
          "0 0 0 3px rgba(66, 153, 225, 0.6)",
          "0 0 0 3px rgba(66, 153, 225, 0.6)"
        )(props),
      },
    }),
  },
};
export default Button;
