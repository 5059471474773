import {mode} from "@chakra-ui/theme-tools";

const Alert = {
    defaultProps: {
        variant: "solid",
    },
    baseStyle: (props) => ({
        container: {
            borderRadius: "md",
        },
    }),
    variants: {
        solid: (props) => ({
            container: {
                bg: mode(`${props.colorScheme}.500`, `${props.colorScheme}.200`)(props),
                color: mode("white", "gray.800")(props),
            },
        }),
    },
};


export default Alert;
