import {mode} from "@chakra-ui/theme-tools";


const Menu = {
    baseStyle: (props) => ({
        list: {
            bg: mode("white", "gray.700")(props),
            borderColor: mode("gray.200", "gray.500")(props),
            boxShadow: mode("sm", "dark-lg")(props),
        },
        item: {
            _hover: {
                bg: mode("gray.100", "gray.600")(props),
            },
            _active: {
                bg: mode("primary.100", "primary.700")(props),
                color: mode("primary.900", "primary.100")(props),
            },
        },
    }),
};


export default Menu;
