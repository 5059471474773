import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Input,
  Progress,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import { BigNumber, ethers } from "ethers";
import {
  commentIsConfirmed,
  createNote,
  getBytes32FromIpfsHash,
  getContent,
  getIpfsHashFromBytes32,
  hashBytes,
  parseComment,
  removeDuplicates,
  sortArray,
} from "../lib/utils";
import { formatDistanceToNow } from "date-fns";
import { selectCommunityById, selectIfUserHasjoined } from "../state/slice";
import { useAppSelector } from "../state/store";
import { useAccount, useContractWrite } from "wagmi";
import { PostContent, User } from "../lib/model";
import { Post as PostClass } from "../lib/post";
import Editor from "../components/Editor";
import { OutputData } from "@editorjs/editorjs";
import useSWR, { useSWRConfig } from "swr";
import { CommentClass } from "../lib/comment";
import { useLoaderContext } from "../context/LoaderContext";
import { CommunityCard } from "../components/CommunityCard/CommunityCard";
import SortBy, { SortByOption } from "../components/SortBy";
import { useValidateUserBalance } from "../utils/useValidateUserBalance";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { motion } from "framer-motion";
import { VoteDownButton, VoteUpButton } from "../components/buttons";
import { useCheckIfUserIsAdminOrModerator } from "../hooks/useCheckIfUserIsAdminOrModerator";
import ForumABI from "../constant/abi/Forum.json";
import { setCacheAtSpecificPath } from "../lib/redis";
import { ForumContractAddress } from "../constant/const";
import { CancelButton } from "../components/buttons";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useSortedVotes } from "../hooks/useSortedVotes";

interface PostProps {
  forumContract: ethers.Contract;
  users: any[];
  provider: ethers.providers.BaseProvider;
}

let postClassInstance: PostClass = null;
let commentClassInstance: CommentClass = null;

interface CommentsMap {
  [key: string]: {
    comment: any;
    isSaving: boolean;
    isEditable: boolean;
    isEditing: boolean;
  };
}

const MotionHeading = motion(Heading);

export default function Post({ forumContract, users, provider }: PostProps) {
  const toast = useToast({
    variant: "solid",
    duration: 7000,
    isClosable: true,
  });
  const { id, postId } = useParams();
  const { address } = useAccount();
  const { isLoading, setIsLoading } = useLoaderContext();

  const { isAdmin, isModerator, fetchIsAdmin, fetchIsModerator } =
    useCheckIfUserIsAdminOrModerator(address);

  const canDelete = isAdmin || isModerator;

  const { data, write } = useContractWrite({
    address: ForumContractAddress as `0x${string}`,
    abi: ForumABI.abi,
    functionName: "removeItem",
    mode: "recklesslyUnprepared",
    onSettled: (data, error) => {
      setIsLoading(false);
    },
    onSuccess: async (data, variables) => {
      try {
        const tx = await data.wait();
        const itemId = variables.args[0];
        const item = await forumContract.itemAt(itemId);
        if (item.kind == 0) {
          await setCacheAtSpecificPath(
            postClassInstance.specificPostId(itemId),
            true,
            "$.removed"
          );
          navigate("../../", { relative: "path" });
        } else if (item.kind == 1) {
          await setCacheAtSpecificPath(
            commentClassInstance.specificCommentId(itemId),
            true,
            "$.removed"
          );
          mutate(
            commentClassInstance.commentsCacheId(),
            (data) => {
              const commentsListCopy = [...data];
              const i = commentsListCopy.findIndex((c) => +c.id === itemId);
              commentsListCopy.splice(i, 1);
              return commentsListCopy;
            },
            { revalidate: false }
          );
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    },
  });

  const navigate = useNavigate();
  const { t } = useTranslation();

  // const [community, setCommunity] = useState(null);
  const community = useAppSelector((state) => selectCommunityById(state, +id));

  const [comment, setComment] = useState<OutputData>(null);
  const [commentsMap, setCommentsMap] = useState<CommentsMap>({} as any);

  const [isPostEditable, setIsPostEditable] = useState(false);
  const [isPostEditing, setPostEditing] = useState(false);
  const [isPostBeingSaved, setPostBeingSaved] = useState(false);

  const [postTitle, setPostTitle] = useState("");
  const [postDescription, setPostDescription] = useState<OutputData>(null);

  const hasUserJoined: User | null = useAppSelector((state) => {
    return selectIfUserHasjoined(state, address, +id);
  });
  const commentEditorRef = useRef<any>();
  const postEditorRef = useRef<any>();

  const identityCommitment = hasUserJoined
    ? BigInt(hasUserJoined?.identityCommitment?.toString())
    : null;

  postClassInstance = new PostClass(postId, id, forumContract, provider);
  commentClassInstance = new CommentClass(
    id,
    postId,
    null,
    forumContract,
    provider
  );

  const { mutate } = useSWRConfig();

  const { data: postFetched, isLoading: postLoading } = useSWR(
    postClassInstance.postCacheId(),
    fetchPost,
    { revalidateOnFocus: false }
  );
  const { data: comments, isLoading: commentsLoading } = useSWR(
    commentClassInstance.commentsCacheId(),
    fetchComments,
    { revalidateOnFocus: false }
  );
  const [tempComments, setTempComments] = useState([]);

  const { validationResult, checkUserBalance } = useValidateUserBalance(
    community,
    address,
    provider
  );

  useEffect(() => {
    //setWaiting(postLoading || commentsLoading);
    // preload(postId, fetchPost);//start fetching before render
    // preload(postClassInstance.commentsCacheId(), fetchComments);//start fetching before render
    fetchIsAdmin();
    fetchIsModerator();
    if (forumContract) {
    }
  }, [forumContract]);

  useEffect(() => {
    fetchIsAdmin();
    fetchIsModerator();
  }, [address]);

  useEffect(() => {
    let listener1, listener2;
    if (hasUserJoined) {
      provider.once("block", () => {
        listener1 = forumContract.on(
          "NewItem",
          async (
            itemType,
            groupId: BigNumber,
            commentId: BigNumber,
            parentId: BigNumber,
            contentCID: string,
            note: BigNumber
          ) => {
            console.log({ itemType, groupId, id, parentId, contentCID, note });
            const signal = contentCID;
            const generatedNote = await createNote(
              hashBytes(signal),
              identityCommitment
            );
            if (
              itemType == 1 &&
              parentId.toString() === postId &&
              note.toString() !== generatedNote.toString()
            ) {
              const p = await forumContract.itemAt(commentId?.toNumber());
              const commentIPFShash = getIpfsHashFromBytes32(p?.contentCID);
              let content;
              let block;

              await Promise.all([
                getContent(commentIPFShash),
                provider.getBlock(p.createdAtBlock.toNumber()),
              ]).then((data) => {
                content = parseComment(data[0]);
                block = data[1];
              });

              console.log(p, content, block);

              mutate(
                commentClassInstance.commentsCacheId(),
                async (commentsData) => {
                  const confirmedComments = [...commentsData];
                  confirmedComments.push({
                    // and replace it with the one wth the proper id
                    content,
                    createdAt: new Date(block.timestamp * 1000),
                    id: p.id.toString(),
                    upvote: p.upvote?.toNumber(),
                    downvote: p.downvote?.toNumber(),
                  });

                  const cleanedComments = sortArray(
                    removeDuplicates(confirmedComments, "id"),
                    "createdAt",
                    true
                  ); //this event sometimes fires multple times
                  return cleanedComments;
                },
                { revalidate: false }
              );
            }
          }
        );
      });

      provider.once("block", () => {
        listener2 = forumContract.on(
          "VoteItem",
          async (
            voteType: 0 | 1,
            itemType: 0 | 1,
            itemId: BigNumber,
            upvote: BigNumber,
            downvote: BigNumber
          ) => {
            console.log("Vote Event", {
              voteType,
              itemType,
              itemId,
              upvote,
              downvote,
            });
            if (itemType === 1) {
              commentClassInstance.updateCommentsVote(
                itemId?.toNumber(),
                voteType,
                true
              );
            } else if (itemType === 0 && itemId?.toNumber() === +postId) {
              // if comment is for the post
              postClassInstance.updatePostVote(voteType, true);
            }
          }
        );
      });
    }

    return () => {
      forumContract.removeAllListeners();
      provider.removeAllListeners();
    };
  }, [hasUserJoined]);

  useEffect(() => {
    postEditorRef?.current?.reRender();
  }, [postFetched]);

  useEffect(() => {
    if (hasUserJoined && identityCommitment) {
      checkIfCommentsAreEditable();
    }
  }, [hasUserJoined, comments]);

  useEffect(() => {
    const note = postFetched?.note;
    const contentCID = postFetched?.contentCID;
    if (hasUserJoined && identityCommitment && contentCID && note) {
      checkIfPostIsEditable(note, contentCID);
    } else {
      setIsPostEditable(false);
    }
  }, [hasUserJoined, postFetched]);

  const checkIfPostIsEditable = async (note, contentCID) => {
    const generatedNote = await createNote(
      hashBytes(getBytes32FromIpfsHash(contentCID)),
      identityCommitment
    );
    const noteBigNumber = BigNumber.from(note).toString();
    const generatedNoteAsBigNumber = BigNumber.from(generatedNote).toString();
    setIsPostEditable(noteBigNumber === generatedNoteAsBigNumber);
  };

  const checkIfCommentsAreEditable = async () => {
    comments?.forEach(async (c) => {
      const note = c?.note;
      const contentCID = c?.contentCID;
      if (note && contentCID) {
        const noteBigNumber = BigNumber.from(note).toString();
        const generatedNote = await createNote(
          hashBytes(getBytes32FromIpfsHash(contentCID)),
          identityCommitment
        );
        const generatedNoteAsBigNumber =
          BigNumber.from(generatedNote).toString();
        if (generatedNoteAsBigNumber === noteBigNumber) {
          setCommentsMap((prevCommentsMap) => {
            return {
              ...prevCommentsMap,
              [c.id]: prevCommentsMap[c.id]
                ? { ...prevCommentsMap[c.id], isEditable: true }
                : { comment: { ...c }, isEditable: true },
            };
          });
        }
      }
    });
  };

  async function fetchPost() {
    return await postClassInstance.get();
  }

  async function fetchComments() {
    return await commentClassInstance.getComments();
  }

  const checkIfUserHasJoined = (toastOnFalse = true) => {
    if (hasUserJoined) {
      return true;
    } else {
      if (toastOnFalse)
        toast({
          title: t("alert.pleaseJoin"),
          variant: "solid",
          status: "error",
          duration: 7000,
          isClosable: true,
        });
      return false;
    }
  };

  const onClickEditPost = async () => {
    const hasSufficientBalance = await checkUserBalance();
    if (!hasSufficientBalance) return;
    setPostEditing(true);
    setPostTitle(postFetched?.title);
    setPostDescription(postFetched?.description);
  };

  const onClickEditComment = (comment) => {
    setCommentsMap({
      ...commentsMap,
      [comment.id]: {
        ...commentsMap[comment.id],
        isSaving: false,
        isEditing: true,
      },
    });
  };

  const setOnEditCommentContent = (comment, content) => {
    setCommentsMap((prevCommentsMap) => {
      return {
        ...prevCommentsMap,
        [comment.id]: {
          ...prevCommentsMap[comment.id],
          comment: { ...comment, content },
        },
      };
    });
  };

  const onClickCancelPost = () => {
    setPostDescription(postFetched?.description);
    setPostEditing(false);
  };

  const deleteItem = async (itemId, itemType: number) => {
    setIsLoading(true);
    await fetchIsAdmin();
    if (canDelete) {
      write({
        recklesslySetUnpreparedArgs: [+itemId],
      });
    } else {
      try {
        const { status } =
          itemType === 0
            ? await postClassInstance.delete(
                address,
                postId,
                users,
                hasUserJoined,
                id,
                setIsLoading
              )
            : await commentClassInstance.delete(
                address,
                postId,
                users,
                hasUserJoined,
                id,
                setIsLoading
              );
        if (status === 200) {
          toast({
            title: t("alert.deleteSuccess"),
            variant: "solid",
            status: "success",
            duration: 7000,
            isClosable: true,
          });
          if (itemType === 0) {
            navigate("../../", { relative: "path" });
          }
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast({
          title: t("alert.deleteFailed"),
          description: error?.error?.toString() ?? error?.toString(),
          variant: "solid",
          status: "error",
          duration: 7000,
          isClosable: true,
        });
        if (itemType === 0) {
          navigate("../../", { relative: "path" });
        }
        setIsLoading(false);
      }
    }
  };

  const onClickCancelComment = (comment) => {
    setCommentsMap((prevCommentsMap) => {
      return {
        ...prevCommentsMap,
        [comment.id]: {
          ...commentsMap[comment.id],
          isEditing: false,
        },
      };
    });
  };

  const addComment = async () => {
    const hasSufficientBalance = await checkUserBalance();
    if (!hasSufficientBalance) return;
    if (!checkIfUserHasJoined()) return;

    let ipfsHash = "";

    try {
      setIsLoading(true);
      //don't await. Create it. If succees, update redis with success, else delete redis
      const { status } = await commentClassInstance.create(
        comment,
        address,
        users,
        hasUserJoined,
        id,
        setIsLoading,
        (comment, cid) => {
          ipfsHash = cid;
          setTempComments([
            {
              id: cid,
              createdAt: new Date(),
              content: comment,
            },
            ...tempComments,
          ]);
        }
      );

      if (status === 200) {
        clearInput();
        // const newMessage = await getContent(getIpfsHashFromBytes32(signal))
        console.log(`Your greeting was posted 🎉`);
      } else {
        console.log("Some error occurred, please try again!");
      }
    } catch (error) {
      console.error(error);
      toast({
        title: t("alert.addCommentFailed"),
        description: error?.error?.toString() ?? error?.toString(),
        variant: "solid",
        status: "error",
        duration: 7000,
        isClosable: true,
      });

      console.log("Some error occurred, please try again!");
    } finally {
      setIsLoading(false);
      setTempComments((prevComments) => {
        const tempCommentIndex = prevComments.findIndex(
          (t) => t.id === ipfsHash
        );
        if (tempCommentIndex > -1) {
          const tempCommentsCopy = [...prevComments];
          tempCommentsCopy.splice(tempCommentIndex, 1);
          return tempCommentsCopy;
        }
      });
    }
  };

  const editPost = async () => {
    const hasSufficientBalance = await checkUserBalance();
    if (!hasSufficientBalance) return;

    setPostBeingSaved(true);
    // setPostEditing(false);
    setIsLoading(true);

    if (!postTitle || !postDescription) {
      toast({
        title: t("alert.fillAllFields"),
        status: "error",
      });
      setIsLoading(false);
      setPostBeingSaved(false);
      return;
    }

    if (
      _.isEqual(postFetched.title, postTitle) &&
      _.isEqual(postFetched.description, postDescription)
    ) {
      toast({
        title: t("alert.noChange"),
        status: "error",
      });
      setIsLoading(false);
      setPostBeingSaved(false);
      return;
    }

    try {
      const postContent: PostContent = {
        title: postTitle,
        description: postDescription,
      };
      const { status } = await postClassInstance.edit(
        postContent,
        address,
        postId,
        users,
        hasUserJoined,
        id,
        setIsLoading
      );

      if (status === 200) {
        setPostEditing(false);
        toast({
          title: t("alert.postEditSuccess"),
          variant: "solid",
          status: "success",
          duration: 7000,
          isClosable: true,
        });
        console.log(`Post Edited Successfully`);
        setIsLoading(false);
        setPostBeingSaved(false);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: t("alert.editFailed"),
        description: error?.error?.toString() ?? error?.toString(),
        variant: "solid",
        status: "error",
        duration: 7000,
        isClosable: true,
      });
      setIsLoading(false);
      setPostBeingSaved(false);
      setPostEditing(true);
    }
  };

  const saveEditedComment = async (comment) => {
    setCommentsMap((prevCommentsMap) => {
      return {
        ...prevCommentsMap,
        [comment.id]: {
          ...prevCommentsMap[comment.id],
          isSaving: true,
          comment: { ...comment },
        },
      };
    });
    setIsLoading(true);

    try {
      const { status } = await commentClassInstance.edit(
        commentsMap[comment.id]?.comment,
        address,
        comment.id,
        hasUserJoined,
        id,
        setIsLoading
      );

      if (status === 200) {
        toast({
          title: t("alert.commentEditSuccess"),
          variant: "solid",
          status: "success",
          duration: 7000,
          isClosable: true,
        });
        setIsLoading(false);
        setCommentsMap((prevCommentsMap) => {
          return {
            ...prevCommentsMap,
            [comment.id]: {
              ...prevCommentsMap[comment.id],
              isSaving: false,
              isEditing: false,
            },
          };
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: t("alert.editFailed"),
        description: error?.error?.toString() ?? error?.toString(),
        variant: "solid",
        status: "error",
        duration: 7000,
        isClosable: true,
      });
      setIsLoading(false);
      setCommentsMap((prevCommentsMap) => {
        return {
          ...prevCommentsMap,
          [comment.id]: { ...prevCommentsMap[comment.id], isSaving: false },
        };
      });
    }
  };

  const clearInput = () => {
    setComment({
      blocks: [],
    });
    commentEditorRef?.current?.clear();
  };

  const vote = async (itemId, voteType: 0 | 1, isComment: boolean) => {
    if (!checkIfUserHasJoined()) return;
    const hasSufficientBalance = await checkUserBalance();
    if (!hasSufficientBalance) return;
    setIsLoading(true);

    try {
      //update the state as voted
      if (isComment) {
        commentClassInstance
          .updateCommentsVote(itemId, voteType, false)
          .then(() => setIsLoading(false));
      } else {
        //it's a vote
        postClassInstance
          .updatePostVote(voteType, false)
          .then(() => setIsLoading(false));
      }

      const { status } = await postClassInstance.vote(
        voteType,
        address,
        users,
        hasUserJoined,
        itemId
      );

      if (status === 200) {
        toast({
          title: t("alert.voteSuccess"),
          variant: "solid",
          status: "success",
          duration: 7000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: t("alert.voteFailed"),
        description: error?.error?.toString() ?? error?.toString(),
        variant: "solid",
        status: "error",
        duration: 7000,
        isClosable: true,
      });
      //revert the vote from the state
      if (isComment) {
        commentClassInstance.updateCommentsVote(itemId, voteType, true, true);
      } else {
        //it's a vote
        postClassInstance.updatePostVote(voteType, true, true);
      }
      setIsLoading(false);
    }
  };

  const CommentActions = ({ comment, canDelete }) => {
    return (
      <Flex mt={3} gap={4} alignItems={"center"}>
        {commentsMap[comment.id]?.isEditable &&
          !commentsMap[comment.id]?.isEditing && (
            <Button
              leftIcon={<EditIcon />}
              fontSize={"small"}
              size={"sm"}
              onClick={() => onClickEditComment(comment)}
            >
              {t("button.edit")}
            </Button>
          )}
        {commentsMap[comment.id]?.isEditing && (
          <CancelButton
            leftIcon={<CloseIcon />}
            fontSize={"small"}
            onClick={() => onClickCancelComment(comment)}
          >
            {t("button.cancel")}
          </CancelButton>
        )}
        {commentsMap[comment.id]?.isEditing && (
          <Button
            disabled={
              !commentsMap[comment.id]?.comment?.content ||
              !commentsMap[comment.id]?.comment?.content?.blocks?.length
            }
            leftIcon={<CheckIcon />}
            fontSize={"small"}
            onClick={() => saveEditedComment(comment)}
          >
            {t("button.save")}
          </Button>
        )}
        {(commentsMap[comment.id]?.isEditable || canDelete) &&
          !commentsMap[comment.id]?.isEditing && (
            <Button
              leftIcon={<DeleteIcon />}
              variant={"link"}
              fontSize={"small"}
              color={"red.500"}
              onClick={() => deleteItem(comment.id, 1)}
            >
              {t("button.delete")}
            </Button>
          )}
      </Flex>
    );
  };

  const hasUserRightsToEdit = async (note, cid) => {
    if (!note || !cid || identityCommitment) return false;

    const signal = cid;
    return createNote(hashBytes(signal), identityCommitment).then((r) => {
      return r;
    });
  };

  const [commentsSortBy, setCommentsSortBy] = useState<SortByOption>("highest");

  const handleCommentsSortChange = (newSortBy: SortByOption) => {
    setCommentsSortBy(newSortBy);
  };

  const sortedCommentsData = useSortedVotes(
    tempComments,
    comments,
    commentsSortBy
  );

  if (!postFetched) return;
  return (
    <>
      <Box gap={10} display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
        <Breadcrumbs />
        <MotionHeading
          as="h2"
          size="xl"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
            duration: 0.5,
          }}
          fontSize={"2xl"}
          fontWeight={"bold"}
          color={"purple.500"}
          textAlign={"center"}
          display={"flex"}
          justifySelf={"center"}
          margin={"auto"}
        >
          {isPostEditing ? (
            <Input
              placeholder={t("placeholder.enterPostTitle")}
              value={postTitle}
              onChange={(e) => setPostTitle(e.target.value)}
            />
          ) : (
            <Text fontWeight={"bold"} fontSize={"larger"} lineHeight={1}>
              {postFetched?.title ?? "-"}
            </Text>
          )}
        </MotionHeading>
      </Box>
      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(3, 1fr)",
        }}
        gap={8}
        justifyItems={"center"}
        px={{ base: 4, md: 0 }}
      >
        <Flex flexDirection={{ base: "column" }} gap={{ base: "6em" }}>
          <CommunityCard community={community} index={1} />
        </Flex>

        <Flex
          flexDirection={"column"}
          mb={5}
          gridColumn={{ md: "span 2" }}
          w={{ base: "100%", md: "100%", lg: "100%" }}
          gap={4}
        >
          <Box>
            {typeof postFetched?.description === "object" && (
              <Box
                style={
                  isPostEditing
                    ? {
                        minHeight: "150px",
                        marginTop: "16px",
                        paddingLeft: "16px",
                        borderRadius: "md",
                      }
                    : {}
                }
              >
                <Editor
                  holder={"post" + id}
                  ref={postEditorRef}
                  readOnly={!isPostEditing}
                  onChange={(val) => setPostDescription(val)}
                  placeholder={t("placeholder.enterPostContent")}
                  data={postFetched?.description}
                />
              </Box>
            )}
          </Box>

          <Flex pt={1} gap={5} marginBlockStart="0">
            <Box pt={3}>
              <Flex gap="3">
                <Box>
                  <VoteUpButton
                    isLoading={isLoading}
                    onClick={() => vote(postFetched?.id, 0, false)}
                    voteCount={postFetched?.upvote}
                  />
                  <VoteDownButton
                    isLoading={isLoading}
                    onClick={() => vote(postFetched?.id, 1, false)}
                    voteCount={postFetched?.downvote}
                  />
                </Box>
                <Text
                  marginBlock={"auto"}
                  fontSize={"small"}
                  display={"inline"}
                >
                  🕛{" "}
                  {postFetched?.createdAt
                    ? formatDistanceToNow(new Date(postFetched?.createdAt), {
                        addSuffix: true,
                      })
                    : "-"}
                </Text>
              </Flex>
              <Flex mt={3} gap={4}>
                {isPostEditable && !isPostEditing && (
                  <Button
                    leftIcon={<EditIcon />}
                    fontSize={"small"}
                    size={"sm"}
                    onClick={onClickEditPost}
                  >
                    {t("button.edit")}
                  </Button>
                )}
                {isPostEditable && isPostEditing && (
                  <Button
                    leftIcon={<CloseIcon />}
                    variant={"link"}
                    fontSize={"small"}
                    color={"danger.500"}
                    onClick={onClickCancelPost}
                  >
                    {t("button.close")}
                  </Button>
                )}

                {isPostEditable && isPostEditing && (
                  <Button
                    disabled={!postDescription?.blocks?.length || !postTitle.length}
                    leftIcon={<CheckIcon />}
                    fontSize={"small"}
                    variant={"solid"}
                    onClick={editPost}
                  >
                    {t("button.save")}
                  </Button>
                )}

                {((isPostEditable && !isPostEditing && !isPostBeingSaved) ||
                  canDelete) && (
                  <Button
                    leftIcon={<DeleteIcon />}
                    variant={"link"}
                    fontSize={"small"}
                    color={"red.500"}
                    onClick={() => deleteItem(postId, 0)}
                  >
                    {t("button.delete")}
                  </Button>
                )}
              </Flex>
            </Box>
          </Flex>

          {isPostBeingSaved && (
            <Progress
              mt={3}
              colorScheme={"primary"}
              size="xs"
              isIndeterminate
            />
          )}

          <Box mt={10}>
            <Box minHeight={"150px"} mt="16px" pl={"16px"} borderRadius="md">
              <Editor
                holder={"post_comment" + id}
                data={comment}
                ref={commentEditorRef}
                onChange={(val) => setComment(val)}
                placeholder="Enter Comment *"
              />
            </Box>
            <Flex justify={"flex-end"} mt="3" gap={4}>
              <Button size={"sm"} mt="1" onClick={clearInput}>
                {t("button.cancel")}
              </Button>
              <Button
                disabled={!comment?.blocks?.length}
                onClick={addComment}
                size={"sm"}
                mt="1"
                colorScheme={"primary"}
                variant={"outline"}
              >
                {t("button.addComment")}
              </Button>
            </Flex>
          </Box>

          <Box mt={10}>
            <Flex justify={"space-between"} align={"center"}>
              <SortBy
                onSortChange={handleCommentsSortChange}
                targetType="comments"
              />

              <Text color={"gray"} fontSize={"small"}>
                💬 {`${comments?.length} Comments`}
              </Text>
            </Flex>

            <Divider mt={"3"} mb={"4"} border="2px" orientation="horizontal" />
            {sortedCommentsData.map((c, i) => (
              <motion.div
                key={i}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Box key={c.id}>
                  <Flex direction="column" mb={8}>
                    <div
                      style={{
                        background:
                          commentIsConfirmed(c.id) ||
                          commentsMap[c?.id]?.isSaving
                            ? useColorModeValue("#f7f7f7", "transparent")
                            : useColorModeValue("#f7f7f7", "transparent"),
                      }}
                    >
                      {c?.content && (
                        <Box
                          style={
                            commentsMap[c?.id]?.isEditing
                              ? {
                                  minHeight: "150px",
                                  marginTop: "16px",
                                  paddingLeft: "16px",
                                  border: "1px solid",
                                  borderRadius: "md",
                                }
                              : {}
                          }
                        >
                          <Editor
                            holder={"comment" + "_" + c?.id}
                            readOnly={!commentsMap[c?.id]?.isEditing}
                            onChange={(val) => setOnEditCommentContent(c, val)}
                            placeholder={t("placeholder.enterPostContent")}
                            data={c?.content?.blocks ? c?.content : []}
                          />
                        </Box>
                      )}
                      {(!commentIsConfirmed(c.id) ||
                        commentsMap[c?.id]?.isSaving) && (
                        <Progress size="xs" isIndeterminate />
                      )}
                    </div>
                    <Box pt={3} color={"gray.500"}>
                      <Flex
                        gap="3"
                        style={{
                          visibility: commentIsConfirmed(c.id)
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        <Box>
                          <VoteUpButton
                            isLoading={isLoading}
                            onClick={() => vote(c?.id, 0, true)}
                            voteCount={c?.upvote}
                          />
                          <VoteDownButton
                            isLoading={isLoading}
                            onClick={() => vote(c?.id, 1, true)}
                            voteCount={c?.downvote}
                          />
                        </Box>
                        <Text
                          marginBlock={"auto"}
                          fontSize={"small"}
                          display={"inline"}
                        >
                          🕛{" "}
                          {c?.createdAt
                            ? formatDistanceToNow(
                                new Date(c?.createdAt).getTime(),
                                { addSuffix: true }
                              )
                            : "-"}
                        </Text>
                      </Flex>
                      {(identityCommitment || canDelete) && (
                        <CommentActions comment={c} canDelete={canDelete} />
                      )}
                    </Box>
                  </Flex>
                </Box>
              </motion.div>
            ))}
          </Box>
        </Flex>
      </Grid>
    </>
  );
}
