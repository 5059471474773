import { Redis } from "@upstash/redis";

const TTLmaxAgeMinutes = 3; //TTL of the cache. How long to hold a cache before refreshing it without interruption to the user
let redisClient: Redis;

const getRedisClient = async () => {
  const client = new Redis({
    url: process.env.REACT_APP_REDIS_URL,
    token: process.env.REACT_APP_REDIS_TOKEN,
  });

  return client;
};

const dateStamp = (data: string) => {
  return [data, Date.now().toString()];
};

const refreshCache = (cachedDate: string) => {
  /*
    caches can get stale for any number of reasons esp during high traffic
    We need to ensure it is at least x minutes fresh
    This is standard practice for caching in high traffic systems
    */
  if (!cachedDate) return true;

  const currentDate = Number(dateStamp("x")[1]);
  const timeDifference = (currentDate - Number(cachedDate)) / 60_000;
  return timeDifference > TTLmaxAgeMinutes;
};

export const setCache = async (key: string, value: any, path = "$") => {
  const datedValue = dateStamp(value);

  if (!redisClient) {
    redisClient = await getRedisClient();
  }

  redisClient.json
    .set(key, path, {
      data: value,
      lastCachedAt: Date.now().toString(),
    })
    .then(
      (success) => {
        console.log("caching successful", key, value);
      },
      (fail) => {
        console.error({ fail, key, value });
      }
    );
};

export const setCacheAtSpecificPath = async (
  key: string,
  value: any,
  path = "$"
) => {
  if (!redisClient) {
    redisClient = await getRedisClient();
  }

  return redisClient.json.set(key, path, value).then(
    async (success) => {
      console.log("caching successful", key, path, value);
      await updateLastCachedAt(key);
    },
    (fail) => {
      console.error({ fail, key, value });
    }
  );
};

export const getCache = async (
  key: string,
  withCleanUp = false
): Promise<{ cache; refresh: boolean }> => {
  if (!redisClient) {
    redisClient = await getRedisClient();
  }

  let cache;
  try {
    const cachedResult = await redisClient.json.get(key);
    // Check if 'data' key exists in the cachedResult
    if (cachedResult && "data" in cachedResult) {
      const { data } = cachedResult;
      cache = data;
      if (cachedResult.hasOwnProperty("lastCachedAt")) {
        const { lastCachedAt } = cachedResult;
        cache["refresh"] = refreshCache(lastCachedAt);
      }
    } else {
      // If 'data' key does not exist, return the entire cachedResult as cache
      cache = cachedResult;
    }
  } catch (error) {
    console.log(key, error);
  }
  const refresh = false;

  if (withCleanUp) {
    try {
      await cacheCommunityCleanUp(cache, key);
    } catch (error) {
      console.error(key, error, "Error cleaning up cache");
    }
  }

  return { cache, refresh: cache?.["refresh"] ?? refresh };
};

const cacheCommunityCleanUp = async (key, cache) => {
  if (
    key.startsWith("group_") &&
    (!cache.hasOwnProperty("ownerIdentity") ||
      !cache.hasOwnProperty("id") ||
      !cache.hasOwnProperty("groupId"))
  ) {
    console.log("cache missing required properties - deleting", key, cache);
    // Remove the cache if it doesn't have the required properties
    await redisClient.del(key);
    cache = null;
  }
};

export const getMCache = async (
  key: string[]
): Promise<{ cache; refresh: boolean }> => {
  if (!redisClient) {
    redisClient = await getRedisClient();
  }

  let data = [];
  let cache;
  let cachedDate = "";
  try {
    const cachedResult = await redisClient.json.mget(key, "$");
    console.log(key, cachedResult);
    cache = cachedResult;
    cachedDate = null;
    console.log(data, cachedDate);
  } catch (error) {
    console.error(key, error);
    cache = [];
  }

  const refresh = false;

  return { cache, refresh };
};

/** Utilities */

const updateLastCachedAt = async (key) => {
  await redisClient.json.set(key, "$.lastCachedAt", Date.now().toString());
};

export const insertAtTop = async (key, value) => {
  try {
    await redisClient.json.arrinsert(key, "$.data", value);
    await updateLastCachedAt(key);
  } catch (error) {
    console.log(key, error);
  }
};

export const insertAtBottom = async (key, value) => {
  try {
    await redisClient.json.arrappend(key, "$.data", value);
    await updateLastCachedAt(key);
  } catch (error) {
    console.log(key, error);
  }
};

export const removeAt = async (key, path) => {
  try {
    await redisClient.json.del(key, path);
  } catch (error) {}
};

export const updateAt = async (key, value) => {
  redisClient.json.arrindex(key, "$.data.*", JSON.stringify({ id: 0 }));
};
