import {mode} from "@chakra-ui/theme-tools";

const Tabs = {
    baseStyle: (props) => ({
        tab: {
            color: mode("gray.600", "gray.400")(props),
            _hover: {
                color: mode("primary.500", "primary.500")(props),
            },
            _selected: {
                color: mode("primary.500", "primary.500")(props),
                borderBottomColor: mode("primary.500", "primary.500")(props),
                borderBottomWidth: "2px",
            },
        },
    }),
};



export default Tabs;
