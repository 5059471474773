import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  CircularProgress,
  useColorModeValue,
} from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useAppSelector } from "../state/store";
import { selectCommunityById } from "../state/slice";
import { Post as PostClass } from "../lib/post";
import useSWR from "swr";
import { goerli, useContract, useProvider } from "wagmi";
import { ForumContractAddress } from "../constant/const";
import ForumABI from "../constant/abi/Forum.json";
import { polygonMumbai } from "wagmi/chains";
import { motion } from "framer-motion";
import EditGroupModal from "./EditGroupModal";

function useBreadcrumbs() {
  const location = useLocation();
  const { id: communityId, postId } = useParams();

  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const { community, postFetched, isValidating } = useCommunityAndPost(
    communityId,
    postId
  );

  useEffect(() => {
    const items = generateBreadcrumbItems(
      community,
      postFetched,
      isValidating,
      location
    );
    setBreadcrumbItems(items);
  }, [communityId, postId, community, postFetched, isValidating]);

  return breadcrumbItems;
}

export const Breadcrumbs = ({ backdrop = false }) => {
  const breadcrumbItems = useBreadcrumbs();
  const navigate = useNavigate();

  const handleNavigation = (e, href) => {
    e.preventDefault();
    navigate(href);
  };

  return (
    <Breadcrumb my={{ base: 4, md: 4 }} px={{ base: 2, md: 4, lg:6 }}>

      {breadcrumbItems?.map((item, index) => (
        <BreadcrumbItem
          backdropFilter={backdrop ? "blur(20px)" : "none"}
          key={index}
          isCurrentPage={item.isCurrentPage}
          opacity={item?.hidden ? 0 : 1}
        >
          <BreadcrumbLink
            href={item.href}
            color={
              item.isCurrentPage
                ? useColorModeValue("primary.500", "primary.400")
                : "gray.400"
            }
            fontWeight={item.isCurrentPage ? "bold" : "normal"}
            onClick={(e) => handleNavigation(e, item.href)}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              {item.label}
            </motion.div>
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
const elipsis = (text, length) => {
  if (text?.length > length) {
    return text.substring(0, length) + "...";
  } else {
    return text;
  }
};
function generateBreadcrumbItems(
  community,
  postFetched,
  isValidating,
  location
) {
  let items = [];

  const communityLabel = elipsis(community?.name, 50) ?? (
    <CircularProgress isIndeterminate size="24px" color="primary.300" />
  );

  const postLabel =
    postFetched && !isValidating ? (
      elipsis(postFetched?.title, 20)
    ) : (
      <CircularProgress isIndeterminate size="24px" />
    );

  if (location.pathname === "/") {
    items = [{ label: "Home", href: "/", isCurrentPage: true, hidden: true }];
  } else if (location.pathname.includes("/posts/")) {
    items = [
      { label: "Home", href: "/", isCurrentPage: false },
      {
        label: communityLabel,
        href: `/communities/${community?.id}`,
        isCurrentPage: false,
      },
      {
        label: postLabel,
        href: `/communities/${community?.id}/posts/${postFetched?.id}`,
        isCurrentPage: true,
      },
    ];
  } else if (location.pathname.includes("/communities/")) {
    items = [
      { label: "Home", href: "/", isCurrentPage: false },
      {
        label: communityLabel,
        href: `/communities/${community?.id}`,
        isCurrentPage: true,
      },
    ];
  } else if (location.pathname.includes("access")) {
    items = [
      { label: "Home", href: "/", isCurrentPage: false },
      {
        label: "Access",
        href: `/access`,
        isCurrentPage: true,
      },
    ];
  }

  return items;
}

function useCommunityAndPost(communityId, postId) {
  const community = useAppSelector((state) =>
    selectCommunityById(state, +communityId)
  );

  const provider = useProvider({ chainId: polygonMumbai.id });
  const forumContract = useContract({
    address: ForumContractAddress,
    abi: ForumABI.abi,
    signerOrProvider: provider,
  });

  const postClassInstance = new PostClass(
    postId,
    communityId,
    forumContract,
    provider
  );

  async function fetchPost() {
    return await postClassInstance.get();
  }

  const { data: postFetched, isValidating } = useSWR(
    postClassInstance.postCacheId(),
    postId ? fetchPost : null,
    { revalidateOnFocus: false }
  );

  return { community, postFetched, isValidating };
}
