import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { FaGlobeAmericas } from "react-icons/fa";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { ToolTip } from "./HOC/ToolTip";

// add forward ref

const LanguageSwitcher = React.forwardRef((props, ref) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useLocalStorage<string>("language", "en");

  // Handle language switching
  const switchLanguage = (language: string) => {
    setLanguage(language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  return (
    <div>
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FaGlobeAmericas />}
          variant="outline"
          colorScheme="primary"
          aria-label="Language Switcher"
        />
        <MenuList>
          <MenuItem
            {...(language === "en" && { "data-active": true })}
            onClick={() => switchLanguage("en")}
          >
            English
          </MenuItem>
          <MenuItem
            {...(language === "es" && { "data-active": true })}
            onClick={() => switchLanguage("es")}
          >
            Español
          </MenuItem>
          <MenuItem
            {...(language === "fr" && { "data-active": true })}
            onClick={() => switchLanguage("fr")}
          >
            Français
          </MenuItem>
          {/* Add more MenuItem elements for additional languages */}
        </MenuList>
      </Menu>
    </div>
  );
});

export default LanguageSwitcher;
