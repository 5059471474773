import {
  Box,
  Container,
  Flex,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Logo } from "./Logo";

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      fontWeight={"600"}
      fontSize={"lg"}
      mb={2}
      color={useColorModeValue("gray.700", "gray.300")}
    >
      {children}
    </Text>
  );
};

export default function Footer() {
  const bgColor = useColorModeValue("gray.100", "gray.900");
  const textColor = useColorModeValue("gray.700", "gray.300");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const { t } = useTranslation();

  return (
    <Box borderTop={"1px solid"} borderColor={borderColor} bg={bgColor} mt={20}>
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={8}>
          <Stack align={{ base: "center", lg: "flex-start" }}>
            <ListHeader>{t("footer.followUs")}</ListHeader>
            {/* Add textColor to the links */}
            <Link
              href="http://discord.gg/43spxvqqmJ"
              color={textColor}
              isExternal
            >
              {t("footer.discord")}
            </Link>
            <Link
              href="https://twitter.com/SacredLogos"
              color={textColor}
              isExternal
            >
              {t("footer.twitter")}
            </Link>
            <Link
              href="https://t.me/SacredLogosOfficial"
              color={textColor}
              isExternal
            >
              {t("footer.telegram")}
            </Link>
            <Link
              href="https://sacredlogos.medium.com/"
              color={textColor}
              isExternal
            >
              {t("footer.medium")}
            </Link>
          </Stack>
          <Stack align={{ base: "center", lg: "flex-start" }}>
            <ListHeader>{t("footer.legal")}</ListHeader>
            {/* Add textColor to the links */}
            <Link
              href="https://sacred-finance.github.io/Sacred_Privacy_Policy.pdf"
              color={textColor}
              isExternal
            >
              {t("footer.privacyPolicy")}
            </Link>
            <Link
              href="https://sacred-finance.github.io/Sacred_Terms_of_Service.pdf"
              color={textColor}
              isExternal
            >
              {t("footer.termsOfService")}
            </Link>
          </Stack>
        </SimpleGrid>
      </Container>
      <Box py={10}>
        <Flex
          align={"center"}
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: borderColor,
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: borderColor,
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Logo />
        </Flex>
        <Text pt={6} fontSize={"sm"} textAlign={"center"} color={textColor}>
          © 2023 Sacred.
        </Text>
      </Box>
    </Box>
  );
}
