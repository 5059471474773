import {
  Box,
  Button,
  Flex,
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputStepper,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { AddIcon, ChevronDownIcon, MinusIcon } from "@chakra-ui/icons";
import { ethers, utils } from "ethers";
import { erc20dummyABI, supportedChains, supportedChainsArray } from "../constant/const";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { isNumber } from "@chakra-ui/utils";
import "./CreateGroupFormUI.css";
import { PictureUpload } from "./PictureUpload";
import { Chain } from "wagmi";
import { ToolTip } from "./HOC/ToolTip";

import { CancelButton, PrimaryButton } from "./buttons";
import { useTranslation } from "react-i18next";
import { polygonMumbai } from "wagmi/chains";

function CreateGroupFormUI({ onCreateGroupClose, onCreate }) {
  const { t } = useTranslation();

  const initialValues = {
    tokenAddress: "",
    minAmount: 0,
    token: "-",
    decimals: 0,
  };
  const formik = useFormik({
    initialValues: {
      tokenRequirements: [initialValues],
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  const [groupName, setGroupName] = useState("");
  const [reqMandatory, setReqMandatory] = useState(true);
  const [er, setEr] = useState({});

  const [bannerFile, setBannerFile] = useState<File | null>(null);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const handleInputChange = (e) => {
    setGroupName(e.target.value);
  };

  const [selectedChain, setSelectedChain] = useState<Chain>(supportedChains[polygonMumbai.id]);




  const handleReqInput = async (e, i) => {
    let val = e.target.value.trim();
    await formik.setFieldValue(
      `tokenRequirements.${i}.tokenAddress`,
      val,
      false
    );
    if (val) {
      if (utils.isAddress(val)) {
        const p = new ethers.providers.JsonRpcProvider(selectedChain.rpcUrls.public.http[0]);
        const contract = new ethers.Contract(val, erc20dummyABI, p);
        const setNameNotFoundError = async () => {
          await formik.setFieldValue(
            `tokenRequirements.${i}.token`,
            "-",
            false
          );
          setEr((e) => {
            return {
              ...e,
              [`tokenRequirements_${i}`]: "Token name not found or Not supported with selected chain!",
            };
          });
        }
        Promise.all([contract?.symbol(), contract?.decimals()])
          ?.then(async ([symbol, decimals]) => {
            if (!symbol) {
              await setNameNotFoundError();
              return;
            }
            await formik.setFieldValue(
              `tokenRequirements.${i}.token`,
              symbol,
              false
            );
            await formik.setFieldValue(
              `tokenRequirements.${i}.decimals`,
              decimals,
              false
            );
            setEr((e) => {
              const errors = { ...e };
              delete errors[`tokenRequirements_${i}`];
              return errors;
            });
          })
          .catch(async (error) => {
            console.log(error);
            await setNameNotFoundError();
          });
      } else {
        setEr((e) => {
          return {
            ...e,
            [`tokenRequirements_${i}`]: "Invalid token address",
          };
        });
        await formik.setFieldValue(`tokenRequirements.${i}.token`, "-", false);
      }
    } else {
      setEr((e) => {
        return {
          ...e,
          [`tokenRequirements_${i}`]: "Required*",
        };
      });
      await formik.setFieldValue(`tokenRequirements.${i}.token`, "-", false);
    }
  };

  const addReq = () => {
    formik.setFieldValue("tokenRequirements", [
      ...formik.values.tokenRequirements,
      initialValues,
    ]);
  };

  const submit = async () => {
    const tokenRequirements = formik.values.tokenRequirements.map((v) => {
      return {
        ...v,
        minAmount: BigInt(v?.minAmount * (10 ** v?.decimals)).toString()
      }
    })
    onCreate({
      name: groupName,
      requirements: reqMandatory ? tokenRequirements : [],
      bannerFile: bannerFile,
      logoFile: logoFile,
      chainId: reqMandatory ? selectedChain.id : polygonMumbai.id,
    });
  };

  const selectChain = async (c: Chain) => {
    setSelectedChain(c);
    await formik.setFieldValue('tokenRequirements', [], false);
    setEr({});
  }

  const isSubmitDisabled =
    !groupName ||
    (reqMandatory &&
      (!formik.isValid ||
        formik.values.tokenRequirements.every(
          (r) =>
            !isNumber(r.minAmount) ||
            !utils.isAddress(r.tokenAddress) ||
            !r?.token ||
            !isNumber(r.decimals)
        )));

  const { logoUrl, bannerUrl } = useMemo(() => {
    return {
      logoUrl: logoFile ? URL.createObjectURL(logoFile) : "",
      bannerUrl: bannerFile ? URL.createObjectURL(bannerFile) : "",
    };
  }, [logoFile, bannerFile]);
  return (
    <>
      <ModalHeader
        background={"primary.500"}
        color={useColorModeValue("white", "white")}
        borderTopRadius={5}
      >
        {t("createCommunity")} <ModalCloseButton mt={2} />
      </ModalHeader>
      <ModalBody my={4}>
        <Flex direction="column" gap={4}>
          <FormControl>
            <Input
              placeholder={t("placeholder.communityName")}
              type="text"
              value={groupName}
              onChange={handleInputChange}
            />
          </FormControl>
          <hr />
          <Flex direction="column" gap={4} p={2}>
            <Flex
              direction="row"
              // justifyContent={"space-between"}
              alignItems={"center"}
            >
              <ToolTip
                shouldWrapChildren={true}
                type={"primary"}
                title={t("toolTip.tokenGating.title")}
                message={t("toolTip.tokenGating.message")}
                fontSize="md"
              >
                <Flex
                  direction="row"
                  justifyContent={"flex-start"}
                  gap={3}
                  alignItems={"center"}
                >
                  <Text fontSize={"lg"}>{t("toolTip.tokenGating.title")}</Text>
                  <Switch
                    id="isChecked"
                    mr={3}
                    isChecked={reqMandatory}
                    onChange={(e) => setReqMandatory(e.target.checked)}
                  />
                </Flex>
              </ToolTip>

              { reqMandatory && <Menu>
                  <MenuButton value={selectedChain.id} as={Button} variant={'outline'} rightIcon={<ChevronDownIcon />}>
                    {selectedChain.name}
                  </MenuButton>
                  <MenuList>
                    {
                    supportedChainsArray.map((k, i) => (
                        <MenuItem value={k.id} key={k.id} onClick={() => selectChain(k)}>{k.name}</MenuItem>
                      ))
                    }
                  </MenuList>
                </Menu>
              }

              <Box display={"inline"} ml={'auto'}>
                <IconButton
                  display={reqMandatory ? "" : "none"}
                  variant={"outline"}
                  onClick={addReq}
                  size="sm"
                  aria-label="add"
                  icon={<AddIcon>{t("button.add")}</AddIcon>}
                ></IconButton>
              </Box>
            </Flex>

            <FormikProvider value={formik}>
              <form onSubmit={submit}>
                <FieldArray
                  name="tokenRequirements"
                  render={({ pop, push, insert, unshift, remove }) => (
                    <Flex direction={"column"} gap={4}>
                      {reqMandatory &&
                        formik.values.tokenRequirements.map((r, i) => (
                          <Flex key={i} w={"full"} direction={"row"} gap="4">
                            <Text pt={2}>{i + 1}.</Text>
                            <FormControl w={"full"}>
                              <InputGroup>
                                <Input
                                  w={"full"}
                                  value={r.tokenAddress}
                                  onChange={(e) => handleReqInput(e, i)}
                                  name={`tokenRequirements.${i}.tokenAddress`}
                                  placeholder={t("placeholder.tokenAddress")}
                                  type="text"
                                />
                                <InputRightAddon
                                  children={
                                    <Text fontSize={"sm"}>
                                      {r?.token ?? "-"}
                                    </Text>
                                  }
                                />
                              </InputGroup>
                              {(er[`tokenRequirements_${i}`] as boolean) && (
                                <Text fontSize={"small"} color="danger">
                                  {er[`tokenRequirements_${i}`]}
                                </Text>
                              )}
                            </FormControl>

                            <FormControl w={"full"}>
                              {/* <FormLabel>Email</FormLabel> */}
                              <NumberInput min={0}>
                                <Input
                                  type={"number"}
                                  defaultValue={r.minAmount}
                                  value={r.minAmount}
                                  onChange={formik.handleChange}
                                  name={`tokenRequirements.${i}.minAmount`}
                                  placeholder={t("placeholder.minAmount")}
                                />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            </FormControl>
                            <Box pt={1}>
                              <IconButton
                                variant={"outline"}
                                onClick={() => remove(i)}
                                size="sm"
                                aria-label="add"
                                icon={<MinusIcon />}
                              ></IconButton>
                            </Box>
                          </Flex>
                        ))}
                    </Flex>
                  )}
                />
              </form>
            </FormikProvider>
          </Flex>

          <Flex direction={"column"} alignItems={"flex-start"} gap={4} p={2}>
            <PictureUpload
              uploadedImageUrl={bannerUrl}
              displayName={t("banner")}
              name={"banner"}
              setImageFileState={setBannerFile}
            />

            <PictureUpload
              uploadedImageUrl={logoUrl}
              displayName={t("logo")}
              name={"logo"}
              setImageFileState={setLogoFile}
            />
          </Flex>
        </Flex>
      </ModalBody>
      <hr />

      <ModalFooter borderBottomRadius={5} gap={4}>
        <CancelButton onClick={onCreateGroupClose}>Close</CancelButton>
        <PrimaryButton disabled={isSubmitDisabled} onClick={submit}>
          {t("button.create")}
        </PrimaryButton>
      </ModalFooter>
    </>
  );
}

export default CreateGroupFormUI;
