import {mode} from "@chakra-ui/theme-tools";

const Tooltip = {
    baseStyle: (props) => ({
        // bg: mode("primary.100", "primary.700")(props),
        // color: mode("primary.900", "primary.100")(props),
        // borderRadius: "md",
        // boxShadow: mode("lg", "dark-lg")(props),
    }),
};


export default Tooltip;
