import { Button } from "@chakra-ui/react";
import React from "react";
import { ButtonProps } from "@chakra-ui/button";

export function PrimaryButton({
  onClick,
  children,
  ...rest
}: {
  onClick: () => Promise<void>;
  children?: React.ReactNode;
} & ButtonProps) {
  return (
    <Button
      onClick={onClick}
      size={"sm"}
      // colorScheme={"primary"}
      borderColor={"primary.400"}
      borderWidth={1}
      {...rest}
    >
      {children}
    </Button>
  );
}
