// theme/index.js
import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

// Global style overrides
// import styles from './styles'
// Color pallettes generated from https://palette.saas-ui.dev/
import colors from "./colors";

// Foundational style overrides
// import borders from './foundations/borders'

const styles = {
  global: (props) => ({
    input: {
      borderColor: mode(
        "primary.500",
        "primary.500"
      )(props),
    },



    select: {
      borderColor: mode(
        "primary.500",
        "primary.500"
      )(props),
    },
    Box: {
      // borderColor: mode("primary.500", "primary.500")(props),
    },

    body: {
      fontFamily: "Roboto, sans-serif", // Example font - replace with the font you want to use
      backgroundColor: mode("light.background", "dark.background")(props),
    },
  }),
};

// Component style overrides
import Button from "./components/Button";
import { mode } from "@chakra-ui/theme-tools";
import Input from "./components/Input";
import Textarea from "./components/TextArea";
import Checkbox from "./components/Checkbox";
import Select from "./components/Select";
import Radio from "./components/Radio";
import Switch from "./components/Switch";
import Menu from "./components/Menu";
import Tag from "./components/Tag";
import Tooltip from "./components/Tooltip";
import Modal from "./components/Modal";
import Popover from "./components/Popover";
import Alert from "./components/Alert";
import Tabs from "./components/Tabs";
import IconButton from "./components/IconButton";
import CloseButton from "./components/CloseButton";
import Breadcrumb from "./components/Breadcrumb";
import Accordion from "./components/Accordion";
import Card from "./components/Card";

const overrides = {
  //   borders,
  colors,

  styles,

  // Other foundational style overrides go here
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  components: {
    Button,
    Input,
    Textarea,
    Checkbox,
    Select,
    Radio,
    Switch,
    Menu,
    Tag,
    Tooltip,
    Modal,
    Popover,
    Alert,
    Tabs,
    IconButton,
    CloseButton,
    Breadcrumb,
    Accordion,
    Card,
  },
};

export default extendTheme(overrides);
