import { useCallback, useContext } from "react";
import { useToast } from "@chakra-ui/react";
import { useLoaderContext } from "../context/LoaderContext";

export const useHandleCommunityAction = () => {
  const toast = useToast();
  const { setIsLoading } = useLoaderContext();

  return useCallback(
    async (
      actionFn: (...args: any[]) => Promise<any>,
      actionParams: any[],
      successMessage: string,
      successCallback?: () => void
    ) => {
      try {
        setIsLoading(true);
        const response = await actionFn(...actionParams);

        const { status, data } = response;

        if (status === 200) {
          toast({
            title: successMessage,
            variant: "solid",
            status: "success",
            duration: 7000,
            isClosable: true,
          });
          if (successCallback) successCallback();
        } else {
          console.log("Error in action");
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    },
    [toast, setIsLoading]
  );
};
