import { Button, Flex, Grid, IconButton, useColorMode } from "@chakra-ui/react";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { CloseIcon } from "@chakra-ui/icons";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { useNavigate } from "react-router-dom";
import { polygonMumbai } from "wagmi/chains";
import {
  MdAccountBalanceWallet,
  MdGroupAdd,
  MdHome,
  MdQuestionMark,
} from "react-icons/md";
import { ToolTip } from "./HOC/ToolTip";
import ThemeToggle from "./ThemeToggle";
import React from "react";
import { useIsMobile } from "../hooks/useIsMobile";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { Logo } from "./Logo";
import { GridProps } from "@chakra-ui/layout";

type HeaderProps = {
  createCommunity(): any;
};

const Header = ({
  createCommunity,
  ...headerProps
}: HeaderProps & GridProps) => {
  const { t } = useTranslation();
  const { colorMode, toggleColorMode } = useColorMode();

  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { isConnected, address } = useAccount();
  const { connect } = useConnect({
    chainId: polygonMumbai.id,
    connector: new MetaMaskConnector({
      options: {
        shimDisconnect: true,
        UNSTABLE_shimOnConnectSelectAccount: true,
      },
    }),
    onSuccess(data, variables, context) {
      console.log(data, variables, context);
    },
  });
  const { disconnect } = useDisconnect();

  const onClickDisconnect = () => {
    disconnect();
  };

  const goHome = () => navigate("/");
  return (
    <Grid
      templateColumns="repeat(3, 1fr)"
      templateRows="repeat(1, 1fr)"
      alignItems="center"
      justifyContent={{ base: "space-around", sm: "space-between" }}
      alignContent="center"
      justifyItems="center"
      paddingBlock={{ base: 2, md: 4, lg: 6 }}
      px={{ base: 2, md: 4, lg: 6 }}
      borderBottomRadius={isMobile ? 0 : "xl"}
      zIndex={50}
      width={"100%"}
      gap={{ base: 1, md: 4, lg: 8 }}
      {...headerProps}
      display={{ base: "flex", sm: "grid" }}
    >
      <ToolTip type={"primary"} title={t("toolTip.homePage")}  >
        <Button
          justifySelf={"flex-start"}
          type="button"
          name="home button"
          padding={0}
          variant="outline"
          colorScheme={"primary"}
          border={isMobile ? undefined : "none"}
          backgroundColor={"transparent !important"}
          _hover={{
            color: "white",
            backgroundColor: isMobile
              ? "primary.500 !important"
              : "transparent",
          }}
          aria-label={`home button`}
          _active={{ backgroundColor: "transparent" }}
          onClick={goHome}
        >
          {isMobile ? <MdHome fontSize={25} /> : <Logo width={300} />}
        </Button>
      </ToolTip>

      <Flex gap={3} display={{ base: "contents", sm: "flex" }}>
        <ToolTip type={"primary"} title={t("toolTip.createCommunity")}>
          <Button
            variant="outline"
            colorScheme={"primary"}
            onClick={createCommunity}
          >
            {isMobile ? (
              <MdGroupAdd fontSize={25} />
            ) : (
              t("toolTip.createCommunity")
            )}
          </Button>
        </ToolTip>

        <ToolTip
          type={"primary"}
          title={t("toolTip.connectWallet", { count: isConnected ? 0 : 1 })}
        >
          <Button
            variant="outline"
            colorScheme={"primary"}
            onClick={() =>
              isConnected ? null : connect({ chainId: polygonMumbai.id })
            }
          >
            {isMobile ? (
              <MdAccountBalanceWallet fontSize={25} />
            ) : isConnected ? (
              address.substring(0, 5) +
              "..." +
              address?.substring(address?.length - 4)
            ) : (
              "Connect Wallet"
            )}
            {isConnected && (
              <CloseIcon
                paddingLeft={"5px"}
                h={"14px"}
                onClick={() => onClickDisconnect()}
              ></CloseIcon>
            )}
          </Button>
        </ToolTip>
      </Flex>
      <Flex gap={3} justifySelf={"end"}  display={{ base: "contents", sm: "flex" }}>
        <ToolTip type={"primary"} title={t("toolTip.externalHelpLink")}>
          <IconButton
            variant="outline"
            colorScheme={"primary"}
            aria-label="help"
            icon={<MdQuestionMark />}
            as="a"
            href="https://www.thatsacred.place/help"
            target="_blank"
            rel="noopener noreferrer"
            justifySelf="flex-start"
            padding={0}
            fontSize={25}
          />
        </ToolTip>

        <ThemeToggle />
        <ToolTip type={"primary"} title={t("toolTip.homePage")}>
          <LanguageSwitcher />
        </ToolTip>
      </Flex>
    </Grid>
  );
};

export default Header;
