import { useLoaderContext } from "../context/LoaderContext";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
} from "@chakra-ui/react";
import Lottie from "react-lottie";
import { useRef } from "react";
import WaitingAnimation from "../../src/assets/waiting.json";

export function LoaderComponent() {
  const { isLoading, setIsLoading } = useLoaderContext();
  const cancelRef = useRef();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: WaitingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <AlertDialog
      isOpen={isLoading}
      leastDestructiveRef={cancelRef}
      onClose={() => setIsLoading(false)}
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          bgColor={"transparent"}
          border="none"
          boxShadow={"none"}
          className={'items-center'}

        >
          <AlertDialogBody>
            <Lottie options={defaultOptions} height={500} width={500} />
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
