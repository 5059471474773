import { Community, User } from "../lib/model";
import { CheckIcon } from "@chakra-ui/icons";
import { useAppSelector } from "../state/store";
import { selectIfUserHasjoined } from "../state/slice";
import { useAccount, useProvider } from "wagmi";
import { useJoinCommunity } from "../hooks/useJoinCommunity";

import React from "react";
import { TosConfirmationWrapper } from "./TermsOfService/TosConfirmationWrapper";
import { polygonMumbai } from "wagmi/chains";
import { useValidateUserBalance } from "../utils/useValidateUserBalance";
import { Button, useToast } from "@chakra-ui/react";
import { ButtonProps } from "@chakra-ui/button";
import { useTranslation } from "react-i18next";

interface JoinButtonProps {
  community: Community;
}

export function JoinCommunityButton({
  community,
  ...rest
}: JoinButtonProps & ButtonProps) {
  const { groupId, name: groupName } = community;
  const { t } = useTranslation();

  const toast = useToast();
  const { address } = useAccount();
  const hasUserJoined: User | null = useAppSelector((state) => {
    return selectIfUserHasjoined(state, address, +groupId);
  });
  const provider = useProvider({ chainId: community.chainId });

  const { checkUserBalance } = useValidateUserBalance(
    community,
    address,
    provider
  );

  const joinCommunity = useJoinCommunity();

  const validateBeforeOpen = async () => {
    if (!address) {
      toast({
        title: t("alert.connectWallet"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    const hasSufficientBalance = await checkUserBalance();
    if (!hasSufficientBalance) return false;
  };
  const join = async () => {
    const result = await validateBeforeOpen();
    if (result === false) return;
    if (!hasUserJoined) await joinCommunity(groupName, groupId);
  };

  const joinButton = (
    <Button
      className={`join-community-button ${hasUserJoined ? "joined" : ""}`}
      // hidden={!!hasUserJoined}
      leftIcon={hasUserJoined ? <CheckIcon /> : undefined}
      {...rest}
    >
      {t("button.join", { count: hasUserJoined ? 0 : 1 })}
    </Button>
  );

  return (
    <>
      {hasUserJoined ? (
        joinButton
      ) : (
        <TosConfirmationWrapper
          buttonElement={joinButton}
          headerText={t("termsOfService.header")}
          descriptionText={t("termsOfService.description")}
          onAgree={join}
          validationBeforeOpen={validateBeforeOpen}
        />
      )}
    </>
  );
}
