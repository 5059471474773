import {useParams} from "react-router-dom";
import {NotFound} from "./NotFound";
import { useCheckIfItemIsRemoved } from "../hooks/useCheckIfItemIsRemoved";

export const ValidateRouteParams = ({children}) => {
    const {id, postId} = useParams();
    const itemRemoved = useCheckIfItemIsRemoved(postId)

    if (id && isNaN(Number(id))) {
        return <NotFound/>;
    }

    if ((postId && isNaN(Number(postId))) || itemRemoved) {
        return <NotFound/>;
    }

    return children;
};
