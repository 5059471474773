import {mode} from "@chakra-ui/theme-tools";

const Tag = {
    baseStyle: (props) => ({
        container: {
            borderRadius: "md",
        },
        label: {
            color: mode("primary.500", "primary.500")(props),
        },
        closeButton: {
            _hover: {
                bg: mode("primary.100", "primary.800")(props),
            },
        },
    }),
    variants: {
        solid: (props) => ({
            container: {
                bg: mode("primary.100", "primary.700")(props),
                color: mode("primary.900", "primary.100")(props),
            },
        }),
    },
};

export default Tag;
