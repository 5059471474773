import {mode} from "@chakra-ui/theme-tools";

const Switch = {
    baseStyle: (props) => ({
        track: {
            bg: mode("gray.300", "whiteAlpha.400")(props),
        },
        thumb: {
            borderColor: mode("primary.500", "primary.500")(props),
            _focus: {
                boxShadow: "0 0 0 2px rgba(116, 105, 246, 0.6)",
            },
        },
        checked: {
            track: {
                bg: "primary.500",
            },
            thumb: {
                borderColor: "primary.500",
            },
        },
    }),
};

export default Switch;
