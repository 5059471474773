import "./App.css";
import {
  Container,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Header from "./components/Header";
import Home from "./pages/Home";
import Community from "./pages/Community";
import Post from "./pages/Post";
import { useEffect, useRef } from "react";
import { useAccount, useContract, useProvider } from "wagmi";
import { BigNumber } from "ethers";
import { ForumContractAddress } from "./constant/const";
import ForumABI from "./constant/abi/Forum.json";
import { Route, Routes } from "react-router-dom";
import { parseBytes32String } from "ethers/lib/utils.js";
import CreateGroupFormUI from "./components/CreateGroupFormUI";
import {
  prependCommunity,
  prependUser,
  selectCommunities,
  selectUsers,
  setUsers,
} from "./state/slice";
import { useAppDispatch, useAppSelector } from "./state/store";
import { startIPFS } from "./lib/utils";
import { NotFound } from "./pages/NotFound";
import { ValidateRouteParams } from "./pages/ValidateRouteParams";
import { PrimaryLayout } from "./components/PrimaryLayout";
import { useFetchCommunities } from "./hooks/useFetchCommunities";
import { useCreateCommunity } from "./hooks/useCreateCommunity";
import { LoaderComponent } from "./components/LoaderComponent";
import { fetchGroupRequirements } from "./utils/communityUtils";
import Access from "./pages/Access";
import { polygonMumbai } from "wagmi/chains";
import { ValidateRouteAccess } from "./pages/ValidateRouteAccess";
import { useTranslation } from "react-i18next";
import { useCheckIfUserIsAdminOrModerator } from "./hooks/useCheckIfUserIsAdminOrModerator";

function App() {
  const toast = useToast();
  const {t} = useTranslation();

  const { address } = useAccount();

  const { isAdmin, fetchIsAdmin } = useCheckIfUserIsAdminOrModerator(address);

  const {
    isOpen: isCreateGroupOpen,
    onOpen: onCreateGroupOpen,
    onClose: onCreateGroupClose,
  } = useDisclosure();

  useFetchCommunities();

  const createCommunity = useCreateCommunity(onCreateGroupClose);
  /** Redux State Variables */
  const dispatch = useAppDispatch();
  const communities = useAppSelector(selectCommunities);

  const users = useAppSelector(selectUsers);
  /** */

  /** Wagmi variables */
  const provider = useProvider({ chainId: polygonMumbai.id });
  let forumContract = useContract({
    address: ForumContractAddress,
    abi: ForumABI.abi,
    signerOrProvider: provider,
  });
  const { isConnected } = useAccount();
  /** */

  const dispatchedGroupIdsRef = useRef(new Set());

  useEffect(() => {
    startIPFS();

    if (!dispatch || !forumContract) return;
    let listener1, listener2;
    provider.once("block", () => {
      listener1 = forumContract.on(
        "NewGroupCreated",
        async (
          groupId: BigNumber,
          name: string,
          creatorIdentityCommitment: BigNumber
        ) => {
          // Check if the groupId has already been dispatched
          if (dispatchedGroupIdsRef.current.has(groupId.toString())) {
            return;
          }
          let userCount = 0;
          try {
            const groupData = await forumContract.groupInfo(groupId.toString());
            userCount = groupData[3]?.toNumber() || 0;

            const requirements = await fetchGroupRequirements(
              forumContract,
              groupId.toString(),
              provider
            );

            dispatch(
              prependCommunity({
                groupId: +groupId.toString(),
                name: name,
                id: +groupId.toString(),
                userCount,
                requirements,
                chainId: groupData[4]?.toNumber(),
                removed: false
              })
            );
            dispatchedGroupIdsRef.current.add(groupId.toString());
          } catch (error) {
            console.log(error);
          }
        }
      );

      listener2 = forumContract.on(
        "NewUser",
        async (
          groupId: BigNumber,
          identityCommitment: BigNumber,
          username: string
        ) => {
          dispatch(
            prependUser({
              groupId: +groupId.toString(),
              name: parseBytes32String(username),
              identityCommitment: identityCommitment.toString(),
            })
          );
        }
      );
    });

    return () => {
      // forumContract.off('NewGroupCreated', listener1)
      // forumContract.off('NewUser', listener2)
    };
  }, [dispatch, forumContract]);

  useEffect(() => {
    forumContract
      ?.queryFilter(forumContract.filters.NewUser())
      .then(async (users) => {
        dispatch(
          setUsers(
            users.map((user: any) => {
              return {
                name: parseBytes32String(user["args"].username),
                groupId: +user["args"].groupId.toString(),
                identityCommitment: user["args"].identityCommitment.toString(),
              };
            })
          )
        );
      });
  }, [isConnected]);

  useEffect(() => {
    fetchIsAdmin()
  }, [address])

  const openCreateCommunityDialog = () => {
    if (!checkIfWalletConnected()) return;
    onCreateGroupOpen();
  };

  const checkIfWalletConnected = () => {
    if (!isConnected) {
      toast({
        title: t("alert.connectWallet"),
        variant: "subtle",
        status: "warning",
        duration: 7000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  return (
    <div className="App">
      <LoaderComponent />
      <Modal
        size={"2xl"}
        isOpen={isCreateGroupOpen}
        onClose={onCreateGroupClose}
      >
        <ModalOverlay />
        <ModalContent>
          <CreateGroupFormUI
            onCreateGroupClose={onCreateGroupClose}
            onCreate={createCommunity}
          ></CreateGroupFormUI>
        </ModalContent>
      </Modal>

      <Container
        maxW={"container.xl"}
        height={"full"}
        alignItems={{
          base: "center",
          sm: "center",
        }}
        className={"app-container"}
      >
          <Header createCommunity={openCreateCommunityDialog} />
          <Routes>
            <Route path="/" element={<PrimaryLayout />}>
              <Route
                index
                path="/"
                element={
                  <Home
                    communities={communities}
                    createCommunity={openCreateCommunityDialog}
                    users={users}
                    isAdmin={isAdmin}
                  />
                }
              />

              <Route
                path="/communities/:id"
                element={
                  <ValidateRouteParams>
                    <Community
                      forumContract={forumContract}
                      provider={provider}
                      users={users}
                    />
                  </ValidateRouteParams>
                }
              />

              <Route
                path="/communities/:id/posts/:postId"
                element={
                  <ValidateRouteParams>
                    <Post
                      provider={provider}
                      users={users}
                      forumContract={forumContract}
                    />
                  </ValidateRouteParams>
                }
              />

              <Route
                path="/access"
                element={
                  <ValidateRouteAccess>
                    <Access />
                  </ValidateRouteAccess>
                }
              />
              <Route path={"*"} element={<NotFound />} />
            </Route>
          </Routes>
      </Container >
    </div>
  );
}

export default App;
