import {
  Box,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  Checkbox,
  Link,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface TosConfirmationWrapperProps {
  buttonElement: React.ReactElement;
  headerText: string;
  descriptionText: string;
  onAgree: () => void;
  validationBeforeOpen?: () => Promise<boolean>;
}

export const TosConfirmationWrapper: React.FC<TosConfirmationWrapperProps> = ({
  buttonElement,
  headerText,
  descriptionText,
  onAgree,
  validationBeforeOpen,
}) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isChecked, setIsChecked] = React.useState(false);

  const handleAgree = () => {
    if (isChecked) {
      onAgree();
      onClose();
    }
  };

  const clonedButtonElement = React.cloneElement(buttonElement, {
    onClick: async () => {
      if (validationBeforeOpen) {
        const result = await validationBeforeOpen();
        if (result === false) return;
      }
      onOpen();
    },
  });

  return (
    <>
      {clonedButtonElement}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{headerText}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="start" spacing={4}>
              <Text>{descriptionText}</Text>
              <Link
                style={{ textDecoration: "underline" }}
                _hover={{ color: "purple.900" }}
                color={"purple.600"}
                href="https://sacred-finance.github.io/Sacred_Terms_of_Service.pdf"
                target="_blank"
                isExternal={true}
              >
                {t("termsOfService.viewTermsOfService")}
              </Link>
              <Checkbox
                isChecked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              >
                {t("termsOfService.agreeCheckBox")}
              </Checkbox>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="solid" onClick={handleAgree} disabled={!isChecked}>
              {t("termsOfService.agreeAndProceed")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
