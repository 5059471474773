import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { AddIcon, Search2Icon } from "@chakra-ui/icons";
import { AnimatePresence, motion } from "framer-motion";
import {
  MdGroupAdd,
  MdGroupRemove,
  MdOutlineAccessTime,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdOutlineMoreTime,
} from "react-icons/md";

import React, { useEffect, useMemo, useState } from "react";
import { Identity } from "@semaphore-protocol/identity";
import { Community } from "../lib/model";
import { useAccount, useProvider } from "wagmi";
import { debounce } from "lodash";
import { CommunityCard } from "../components/CommunityCard/CommunityCard";
import { polygonMumbai } from "wagmi/chains";
import "./Home.css";
import { FilterButtons } from "../components/buttons";
import { useLoaderContext } from "../context/LoaderContext";
import { useTranslation } from "react-i18next";
import { MotionFlexBanner } from "../components/MotionFlexBanner";

const MotionHeading = motion(Heading);
const MotionDiv = motion.div;

interface HomeProps {
  communities: Community[];
  users: any[];
  createCommunity: Function;
  isAdmin: boolean;
}

function Home({ communities = [], createCommunity, users, isAdmin = false }: HomeProps) {
  const provider = useProvider({ chainId: polygonMumbai.id });

  const { isLoading, setIsLoading } = useLoaderContext();
  /** groupMap is only to store the groupName as key and whether the connected user has already joined it or not */
  const [groupMap, setGroupMap] = useState<any>({});

  const [searchTerm, setSearchTerm] = useState("");

  const [localCommunities, setLocalCommunities] = useState<Community[]>([]);

  const { address } = useAccount();
  const { t } = useTranslation();
  /** To prepare a map of the group already joined by the user connected */
  useEffect(() => {
    if (communities.length) {
      const gMap = {};
      // Filter out duplicate communities based on their groupId and name
      const uniqueCommunities = Array.from(
        new Set(
          communities.map((c) =>
            JSON.stringify({ groupId: c.groupId, name: c.name })
          )
        )
      ).map((communityKey) =>
        communities.find(
          (c) =>
            JSON.stringify({ groupId: c.groupId, name: c.name }) ===
            communityKey
        )
      );

      uniqueCommunities.forEach((c) => {
        gMap[c.id?.toString()] = users.find((u) => {
          const generatedIdentity = new Identity(
            `${address}_${c.groupId}_${u.name}`
          );
          const userCommitment = generatedIdentity
            .generateCommitment()
            .toString();
          return (
            +u?.groupId === +c.groupId &&
            u?.identityCommitment === userCommitment
          );
        });
      });
      setGroupMap(gMap);
      // Update the localCommunities state with uniqueCommunities
      if (!searchTerm) {
        setLocalCommunities(uniqueCommunities);
      }
    }
  }, [users, communities]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handleSearchChange = (e) => {
    const val = e.target.value;
    setSearchTerm(val);

    let listToDisplay = communities;

    if (val) {
      listToDisplay = communities.filter((c) => {
        return c.name.toLowerCase().includes(val.toLowerCase());
      });
      setLocalCommunities(listToDisplay);
    } else {
      setLocalCommunities(communities);
    }
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 400);
  }, [communities]);

  const [currentFilter, setCurrentFilter] = useState("Alphabetical");
  const applyFilter = (filter) => {
    let filteredCommunities = [...localCommunities];

    switch (filter) {
      case "Alphabetical":
        if (currentFilter === "Alphabetical") {
          setCurrentFilter("-Alphabetical");
          filteredCommunities.sort((a, b) => b.name.localeCompare(a.name));
        } else {
          setCurrentFilter("Alphabetical");
          filteredCommunities.sort((a, b) => a.name.localeCompare(b.name));
        }
        break;
      case "User Count":
        if (currentFilter === "User Count") {
          setCurrentFilter("-User Count");
          filteredCommunities.sort((a, b) => b.userCount - a.userCount);
        } else {
          setCurrentFilter("User Count");
          filteredCommunities.sort((a, b) => a.userCount - b.userCount);
        }
        break;

      case "Age":
        if (currentFilter === "Age") {
          setCurrentFilter("-Age");
          filteredCommunities.sort((a, b) => b.groupId - a.groupId);
        } else {
          setCurrentFilter("Age");
          filteredCommunities.sort((a, b) => a.groupId - b.groupId);
        }
        break;
      default:
        break;
    }

    setLocalCommunities(filteredCommunities);
  };

  return (
    <>
      <MotionFlexBanner
        justifyContent={{ base: "center", lg: "space-between" }}
        alignContent={{ base: "center" }}
        alignItems={{ base: "center", md: "center" }}
        flexWrap={{ base: "wrap" }}
        flexDirection={{ base: "column", lg: "row" }}
      >
        <MotionHeading
          as={"div"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
            duration: 0.5,
          }}
          fontSize={{ base: "2rem", md: "3rem" }}
          color={useColorModeValue("primary.500", "white")}
          fontWeight={"bold"}
        >
          {t("pageTitle.communities")}
        </MotionHeading>

        <InputGroup
          size={"lg"}
          justifyContent={"start"}
          marginLeft={{ base: 0, md: 50 }}
          minWidth={{ base: 150, md: 300 }}
          maxWidth={{ base: 300, md: 300 }}
        >
          <InputLeftElement pointerEvents="none">
            <Search2Icon
              color={useColorModeValue("primary.500", "primary.300")}
            />
          </InputLeftElement>
          <Input
            width={"100%"}
            onChange={debouncedResults}
            background={useColorModeValue("#fff", "#1A1A1AFF")}
            variant="outline"
            placeholder="Explore"
            borderColor={useColorModeValue("gray.300 !important", "gray.700")}
            borderRadius="md"
            _placeholder={{
              color: useColorModeValue("gray.500", "gray.400"),
            }}
          />
        </InputGroup>
        <FilterButtons
          filters={[
            {
              name: "Alphabetical",
              icon: (
                <>
                  A{" "}
                  {currentFilter === "-Alphabetical" ? (
                    <MdOutlineKeyboardArrowUp />
                  ) : (
                    <MdOutlineKeyboardArrowDown />
                  )}{" "}
                </>
              ),
            },
            {
              name: "User Count",
              icon:
                currentFilter === "-User Count" ? (
                  <MdGroupAdd />
                ) : (
                  <MdGroupRemove />
                ),
            },
            {
              name: "Age",
              icon:
                currentFilter === "-Age" ? (
                  <MdOutlineMoreTime />
                ) : (
                  <MdOutlineAccessTime />
                ),
            },
          ]}
          activeFilter={currentFilter}
          displayName={false}
          onFilter={applyFilter}
        />
      </MotionFlexBanner>

        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          alignSelf={"center"}
          gap={4}
          rowGap={16}
          px={{ base: 4, md: 0 }}
        >
          {localCommunities.map((community, index) => (
            <CommunityCard
              key={community?.groupId}
              community={community}
              index={index}
              isAdmin={isAdmin}
            />
          ))}
        </Grid>

      {localCommunities.length === 0 && (
        <Flex
          direction={"column"}
          justifyContent={"center"}
          mt="20%"
          alignItems={"center"}
        >
          <Text textAlign={"center"} fontSize="xl">
            {t("noResultsFound")}
          </Text>
        </Flex>
      )}

      {communities.length === 0 && !isLoading && (
        <Flex
          direction={"column"}
          justifyContent={"center"}
          mt="20%"
          alignItems={"center"}
        >
          <Text textAlign={"center"} fontSize="xl">
            0 Communities :(
          </Text>
          <Button
            mt={4}
            colorScheme={"primary"}
            onClick={() => createCommunity()}
            leftIcon={<AddIcon />}
          >
            Create
          </Button>
        </Flex>
      )}
    </>
  );
}

export default Home;
