import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import { ToolTip } from "./HOC/ToolTip";
import { useTranslation } from "react-i18next";
import { useHandleFileImageUpload } from "../utils/communityUtils";

export const PictureUpload = (props: {
  uploadedImageUrl?: string;
  displayName: string;
  name: "banner" | "logo";
  setImageFileState: Dispatch<SetStateAction<File>>;
}) => {
  const toast = useToast({
    duration: 7000,
    isClosable: true,
    variant: "solid",
  });

  const handleFileImageUpload = useHandleFileImageUpload(
    props.setImageFileState,
    toast
  );

  const { t } = useTranslation();
  const inputRef = useRef(null);
  const imageRef = useRef(null);
  const [isDeleteIconVisible, setIsDeleteIconVisible] = useState(false);
  const openFileUpload = () => {
    if (props.uploadedImageUrl) return;
    inputRef.current.click();
  };

  useEffect(() => {
    if (!imageRef?.current) return;

    // on hover show edit button
    imageRef.current.addEventListener("mouseenter", () => {
      setIsDeleteIconVisible(true);
    });
    imageRef.current.addEventListener("mouseleave", () => {
      setIsDeleteIconVisible(false);
    });

    return () => {
      imageRef?.current?.removeEventListener?.("mouseenter", () => {});
      imageRef?.current?.removeEventListener?.("mouseleave", () => {});
    };
  }, [imageRef]);

  return (
    <Flex
      direction={"column"}
      width={"100%"}
      gap={4}
      position={"relative"}
      alignContent={"end"}
      alignItems={"center"}
    >
      <FormControl
        background={"primary.500"}
        color={"white"}
        borderRadius={5}
        className={"image-upload-container"}
        onClick={openFileUpload}
        _hover={!props.uploadedImageUrl ? { filter: "brightness(0.8)" } : {}}
      >
        <ToolTip
          shouldWrapChildren={true}
          background={"primary.500"}
          type={"info"}
          label={
            <Box>
              <Text fontWeight="bold" color={"white"}>
                {props.displayName} &nbsp;
              </Text>
              <Text color={"white"}>
                {t("toolTip.uploadImage.message", {
                  displayName: props.displayName,
                })}
              </Text>
            </Box>
          }
          fontSize="md"
        >
          <FormLabel
            htmlFor={props.displayName}
            className={"image-upload-label"}
          >
            {props.uploadedImageUrl ? (
              <>
                {t("display", { displayName: props.displayName })}{" "}
                <CheckIcon justifySelf={"end"} />
              </>
            ) : (
              <>
                {t("upload", { displayName: props.displayName })}
                <AddIcon />
              </>
            )}
          </FormLabel>
        </ToolTip>
        <Input
          hidden={true}
          ref={inputRef}
          type="file"
          name={props.name}
          accept="image/jpeg, image/png, image/jpg, image/webp, image/gif, image/svg+xml, image/avif"
          onChange={handleFileImageUpload}
        />
      </FormControl>

      <ToolTip type={"danger"} title={t("toolTip.delete")}>
        <picture ref={imageRef}>
          <img
            style={{
              opacity: isDeleteIconVisible ? 0.5 : 1,
              borderRadius: props.name === 'banner' ? 5 : "50%",
            }}
            width={props.name === 'banner' ? "100%" : 200}
            height={props.name === 'banner' ? "100%" : 200}

            onClick={() => {
              inputRef.current.value = "";
              props.setImageFileState(null);
            }}
            src={props.uploadedImageUrl}
            alt=""
          />
        </picture>
      </ToolTip>
      {isDeleteIconVisible && (
        <DeleteIcon
          pointerEvents={"none"}
          position={"absolute"}
          top={"60%"}
          width={"50px"}
          height={"50px"}
          color={"danger.500"}
          right={"50%"}
          transform={"translate(50%, -50%)"}
          aria-label={"delete-image"}
        />
      )}
    </Flex>
  );
};
