import { SolidityProof } from "@semaphore-protocol/proof";
import axios from "axios";
import { BigNumberish } from "ethers";
import { RELAYER_URL } from "../constant/const";
import { Requirement } from "./model";

export async function joinGroup(groupId: string, identityCommitment: string, username: string) {
  return axios.post(`${RELAYER_URL}/join-group`, {
    identityCommitment,
    groupId,
    username
  })
}

export async function createGroup(identityCommitment: string, requirements: Requirement[], groupName: string, chainId: number) {
  return axios.post(`${RELAYER_URL}/create-group`, {
    identityCommitment,
    groupName,
    requirements,
    chainId
  });
}

export async function createPost(contentCID: string, note:BigInt, groupId: string, merkleRoot: BigNumberish, nullifierHash: BigNumberish, solidityProof: SolidityProof) {
  return axios.post(`${RELAYER_URL}/post`, {
    contentCID,
    note: note.toString(),
    groupId,
    merkleRoot,
    nullifierHash,
    solidityProof
  });
}

export async function createComment(contentCID: string, note:BigInt, groupId: string, parentId: string, merkleRoot: BigNumberish, nullifierHash: BigNumberish, solidityProof: SolidityProof) {
  return axios.post(`${RELAYER_URL}/comment`, {
    contentCID,
    note: note.toString(),
    groupId,
    parentId,
    merkleRoot,
    nullifierHash,
    solidityProof
  });
}

export async function edit(itemId: string, contentCID: string, note:BigInt, a, b, c) {
  return axios.post(`${RELAYER_URL}/edit`, {
    a,
    b,
    c,
    itemId,
    contentCID,
    note: note.toString()
  });
}

export async function vote(itemId: string, groupId: string, type: number, merkleRoot: BigNumberish, nullifierHash: BigNumberish, solidityProof: SolidityProof) {
  return axios.post(`${RELAYER_URL}/vote`, {
    itemId,
    groupId,
    type,
    merkleRoot,
    nullifierHash,
    solidityProof
  })
}
