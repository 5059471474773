import {useParams} from "react-router-dom";
import {NotFound} from "./NotFound";
import { useCheckIfItemIsRemoved } from "../hooks/useCheckIfItemIsRemoved";
import { useCheckIfUserIsAdminOrModerator } from "../hooks/useCheckIfUserIsAdminOrModerator";
import { useAccount } from "wagmi";
import { Progress, useToast } from "@chakra-ui/react";
import { useEffect } from "react";

export const ValidateRouteAccess = ({children}) => {
    const { address } = useAccount();
    const { isAdmin, isLoading, isFetching, isFetched, fetchIsAdmin, isAdminFetchStatus } = useCheckIfUserIsAdminOrModerator(address);
    const toast = useToast();

    console.log({isAdminFetchStatus, isAdmin})

    useEffect(() => {
        if(address) {
            fetchIsAdmin()
        }
        if (!address) {
            toast({
                title: 'Please Connect your wallet!',
                variant: 'solid',
                status: 'error'
            })
        }
    }, [address])



    if (isAdmin) {
        return children
    }

    if (isAdmin === false || !address) {
        return <NotFound></NotFound>
    }
};
