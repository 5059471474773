import {mode} from "@chakra-ui/theme-tools";

const IconButton = {
    // baseStyle: (props) => ({
    //     borderColor: mode("primary.500", "primary.500")(props),
    //     bg: mode("white", "gray.700")(props),
    //     _hover: {
    //         bg: mode("gray.100", "gray.600")(props),
    //     },
    //     _active: {
    //         borderColor: mode("primary.500", "primary.500")(props),
    //         bg: mode("primary.500", "primary.500")(props),
    //     },
    //
    // }),
};

export default IconButton;
