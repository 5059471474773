import React from "react";
import { Flex, Grid, SimpleGrid, useColorMode } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FlexProps } from "@chakra-ui/layout";
const MotionGrid = motion(Grid);

export function MotionFlexBanner({
  children,
  ...props
}: { children: React.ReactNode } & FlexProps) {
  const { colorMode } = useColorMode();

  return (
    <Flex
      justifyContent={"space-between"}
      alignItems={{ base: "center", sm: "flex-end" }}
      direction={{ base: "column", sm: "row" }}
      top={2}
      width={"100%"}
      backgroundColor={"transparent"}
      gap={4}
      {...props}
      borderBottomRadius={"md"}
    >
      {children}
    </Flex>
  );
}
