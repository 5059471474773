import { Button, Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { DownVoteIcon, UpVoteIcon } from "../../constant/icons";

const VoteButton = ({
  icon,
  ariaLabel,
  hoverBg,
  voteCount,
  borderColor,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Button
      variant={"solid"}
      as={"button"}
      aria-label={ariaLabel}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
      px={2}
      size={"xss"}

      py={2}
      leftIcon={React.cloneElement(icon, {
        width: "20px",
        height: "20px",
      })}
    >
      <Text fontSize={"sm"}>{voteCount}</Text>
    </Button>
  );
};

// Higher Order Components
const withVoteUpButton = (Component) => (props) =>
  (
    <Component
      icon={<UpVoteIcon fill={"success.500"} stroke={"success.500"} />}
      ariaLabel="upvote"
      borderRight={"none"}


      borderRightRadius="0"
      {...props}
    />
  );

const withVoteDownButton = (Component) => (props) =>
  (
    <Component
      icon={<DownVoteIcon fill={"danger.500"} stroke={"danger.500"} />}
      ariaLabel="downvote"

      borderLeftRadius="0"
      {...props}
    />
  );

export const VoteUpButton = withVoteUpButton(VoteButton);
export const VoteDownButton = withVoteDownButton(VoteButton);
