import "./index.css";
import reportWebVitals from "./reportWebVitals";

import ReactDOM from "react-dom/client";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from "@chakra-ui/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import {
  hardhat,
  localhost,
  goerli,
  mainnet,
  polygonMumbai,
} from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { Provider } from "react-redux";
import store from "./state/store";
import { LoaderProvider } from "./context/LoaderContext";
import "./i18n";
const root = ReactDOM.createRoot(document.getElementById("root"));

const { provider, webSocketProvider } = configureChains(
  [localhost, hardhat, goerli, mainnet, polygonMumbai],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id === localhost.id) {
          return {
            http: process.env.REACT_APP_LOCALHOST_URL ?? "",
          };
        } else if (chain.id === goerli.id) {
          return {
            http: process.env.REACT_APP_GOERLI_URL ?? "",
          };
        } else if (chain.id === mainnet.id) {
          return {
            http: process.env.REACT_APP_MAINNET_URL ?? "",
          };
        } else if (chain.id === polygonMumbai.id) {
          return {
            http: process.env.REACT_APP_POLYGON_MUMBAI_URL ?? "",
          };
        }
        return null;
      },
    }),
    publicProvider(),
  ]
);

const client = createClient({
  autoConnect: true,
  // provider: getDefaultProvider({ name: 'goerli', chainId: 5 }),
  // provider: ethers.providers.getDefaultProvider('http://localhost:8545'),
  provider: provider({ chainId: polygonMumbai.id }),
  webSocketProvider,
  connectors: [
    new MetaMaskConnector({
      options: {
        shimDisconnect: true,
        UNSTABLE_shimOnConnectSelectAccount: true,
      },
    }),
  ],
});

root.render(
  <LoaderProvider>
    <ChakraProvider theme={theme}>
      {/* <React.StrictMode> */}
      <Provider store={store}>
        <WagmiConfig client={client}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </WagmiConfig>
      </Provider>

      {/* </React.StrictMode> */}
    </ChakraProvider>
  </LoaderProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
