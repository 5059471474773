import { mode } from "@chakra-ui/theme-tools";
import {ThemeComponents} from "@chakra-ui/react";

const Input:  ThemeComponents["Input"] = {
    baseStyle: (props) => ({
        input: {
            // borderColor: mode("gray.500", "gray.500")(props),
            bg: mode("white", "gray.700")(props),
        },
        field: {
            borderColor: mode("primary.500", "primary.500")(props),
            _focus: {
                borderColor: "primary.500",
                boxShadow: "0 0 0 2px rgba(116, 105, 246, 0.6)",
            },
            _hover: {
                borderColor: "primary.600",
                cursor: "pointer",
            },

        },
    }),
};

export default Input;
