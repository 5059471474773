import { mode } from "@chakra-ui/theme-tools";

const Modal = {
  baseStyle: (props) => ({
    dialog: {
      bg: mode("white", "gray.800")(props),
      color: mode("gray.800", "white")(props),
      borderRadius: "md",
    },
    header: {
      bg: mode("primary.500", "primary.500")(props),
      color: mode("white", "gray.800")(props),
    },
    body: {
      color: mode("gray.800", "white")(props),


      // bg: mode("gray.100", "gray.800")(props),
      input: {
        bg: mode("gray.100", "gray.900")(props),
        color: mode("gray.800", "white")(props),
        _placeholder: {
          color: mode("gray.400", "gray.500")(props),
        },
        borderColor: mode("gray.300", "gray.600")(props),
        _hover: {
          borderColor: mode("primary.500", "primary.300")(props),
        },
        _focus: {
          borderColor: mode("primary.500", "primary.300")(props),
          boxShadow: mode(
            "0 0 0 3px rgba(66, 153, 225, 0.6)",
            "0 0 0 3px rgba(66, 153, 225, 0.6)"
          )(props),
        },
      },
    },
    footer: {
      bg: mode("gray.100", "gray.800")(props),
      borderTopWidth: "1px",
      borderColor: mode("gray.200", "gray.600")(props),
    },
  }),
};

export default Modal;
